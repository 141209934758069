export const GET_COMPANY_FEED = "GET_COMPANY_FEED";
export const GET_COMPANY_FEED_SUCCESS = "GET_COMPANY_FEED_SUCCESS";
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS";
export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS";
export const CLEAR_COMPANY_DETAILS = "CLEAR_COMPANY_DETAILS";
export const GET_COMPANY_CONTACTS = "GET_COMPANY_CONTACTS";
export const GET_COMPANY_CONTACTS_SUCCESS = "GET_COMPANY_CONTACTS_SUCCESS";
export const GET_COMPANY_JLL_CONTACTS = "GET_COMPANY_JLL_CONTACTS";
export const GET_COMPANY_JLL_CONTACTS_SUCCESS = "GET_COMPANY_JLL_CONTACTS_SUCCESS";
export const CLEAR_COMPANY_CONTACTS = "CLEAR_COMPANY_CONTACTS";

export function getCompanyFeed(data) {
    return {
        payload: data,
        type: GET_COMPANY_FEED,
    };
}

export function getCompanyFeedSuccess(data) {
    return {
        payload: data,
        type: GET_COMPANY_FEED_SUCCESS,
    };
}
export function getCompanyDetails(data) {
    return {
        payload: data,
        type: GET_COMPANY_DETAILS,
    };
}

export function getCompanyDetailsSuccess(data) {
    return {
        payload: data,
        type: GET_COMPANY_DETAILS_SUCCESS,
    };
}

export function clearCompanyDetails(data) {
    return {
        payload: data,
        type: CLEAR_COMPANY_DETAILS,
    };
}

export function getCompanyContacts(data) {
    return {
        payload: data,
        type: GET_COMPANY_CONTACTS,
    };
}

export function getCompanyContactsSuccess(data) {
    return {
        payload: data,
        type: GET_COMPANY_CONTACTS_SUCCESS,
    };
}
export function getCompanyJllContacts(data) {
    return {
        payload: data,
        type: GET_COMPANY_JLL_CONTACTS,
    };
}

export function getCompanyJllContactsSuccess(data) {
    return {
        payload: data,
        type: GET_COMPANY_JLL_CONTACTS_SUCCESS,
    };
}

export function clearCompanyContacts(data) {
    return {
        payload: data,
        type: CLEAR_COMPANY_CONTACTS,
    };
}