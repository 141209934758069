import { BASE_URL, AuthHeaders } from "./config";
import axios from "axios";

export const fetchContactById = async (data) => {
    const id = data && data.id ? data.id : '';
    const url = data.type.includes('j') ? `${BASE_URL}/contacts/jllcontact/${id}` : `${BASE_URL}/contacts/${id}`;
    const response = await axios.get(
        url,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};

export const editContactDetails = async (data) => {


    const options = {
        method: 'PUT',
        headers: {
            ...AuthHeaders()
        },
    };

    let addEditUrl = data.type === 'contacts' ? `${BASE_URL}/contacts/${data.id}/setstatus/${data.status}` : `${BASE_URL}/contacts/jllcontacts/${data.jllContactId}/setstatus/${data.status}`;
    let response;
    // Create New
    response = data.type === 'contacts' ? await axios.post(addEditUrl, data, options) : await axios.put(addEditUrl, data, options);
    return response.data;
  };

  export const addEditContact = async (data) => {
    const options = {
        method: data.id === null ? 'POST' : 'PUT',
        headers: {
            ...AuthHeaders()
        },
    };

    let response;
    data.id === null
    ? response = await axios.post(`${BASE_URL}/contacts`, data, options)
    : response = await axios.put(`${BASE_URL}/contacts`, data, options);
    return response.data;
  };
