const states = [
  {
    id: 1296,
    name: 'united states',
    value: [
      { id: 'AL', name: 'AL - Alabama' },
      { id: 'AK', name: 'AK - Alaska' },
      { id: 'AS', name: 'AS - American Samoa' },
      { id: 'AZ', name: 'AZ - Arizona' },
      { id: 'AR', name: 'AR - Arkansas' },
      { id: 'CA', name: 'CA - California' },
      { id: 'CO', name: 'CO - Colorado' },
      { id: 'CT', name: 'CT - Connecticut' },
      { id: 'DE', name: 'DE - Delaware' },
      { id: 'DC', name: 'DC - District Of Columbia' },
      { id: 'FM', name: 'FM - Federated States Of Micronesia' },
      { id: 'FL', name: 'FL - Florida' },
      { id: 'GA', name: 'GA - Georgia' },
      { id: 'GU', name: 'GU - Guam' },
      { id: 'HI', name: 'HI - Hawaii' },
      { id: 'ID', name: 'ID - Idaho' },
      { id: 'IL', name: 'IL - Illinois' },
      { id: 'IN', name: 'IN - Indiana' },
      { id: 'IA', name: 'IA - Iowa' },
      { id: 'KS', name: 'KS - Kansas' },
      { id: 'KY', name: 'KY - Kentucky' },
      { id: 'LA', name: 'LA - Louisiana' },
      { id: 'ME', name: 'ME - Maine' },
      { id: 'MH', name: 'MH - Marshall Islands' },
      { id: 'MD', name: 'MD - Maryland' },
      { id: 'MA', name: 'MA - Massachusetts' },
      { id: 'MI', name: 'MI - Michigan' },
      { id: 'MN', name: 'MN - Minnesota' },
      { id: 'MS', name: 'MS - Mississippi' },
      { id: 'MO', name: 'MO - Missouri' },
      { id: 'MT', name: 'MT - Montana' },
      { id: 'NE', name: 'NE - Nebraska' },
      { id: 'NV', name: 'NV - Nevada' },
      { id: 'NH', name: 'NH - New Hampshire' },
      { id: 'NJ', name: 'NJ - New Jersey' },
      { id: 'NM', name: 'NM - New Mexico' },
      { id: 'NY', name: 'NY - New York' },
      { id: 'NC', name: 'NC - North Carolina' },
      { id: 'ND', name: 'ND - North Dakota' },
      { id: 'MP', name: 'MP - Northern Mariana Islands' },
      { id: 'OH', name: 'OH - Ohio' },
      { id: 'OK', name: 'OK - Oklahoma' },
      { id: 'OR', name: 'OR - Oregon' },
      { id: 'PW', name: 'PW - Palau' },
      { id: 'PA', name: 'PA - Pennsylvania' },
      { id: 'PR', name: 'PR - Puerto Rico' },
      { id: 'RI', name: 'RI - Rhode Island' },
      { id: 'SC', name: 'SC - South Carolina' },
      { id: 'SD', name: 'SD - South Dakota' },
      { id: 'TN', name: 'TN - Tennessee' },
      { id: 'TX', name: 'TX - Texas' },
      { id: 'UT', name: 'UT - Utah' },
      { id: 'VT', name: 'VT - Vermont' },
      { id: 'VI', name: 'VI - Virgin Islands' },
      { id: 'VA', name: 'VA - Virginia' },
      { id: 'WA', name: 'WA - Washington' },
      { id: 'WV', name: 'WV - West Virginia' },
      { id: 'WI', name: 'WI - Wisconsin' },
      { id: 'WY', name: 'WY - Wyoming' },
    ],
  },
  {
    id: 2442,
    name: 'canada',
    value: [
      { id: 'AB', name: 'AB - Alberta' },
      { id: 'BC', name: 'BC - British Columbia' },
      { id: 'MB', name: 'MB - Manitoba' },
      { id: 'NB', name: 'NB - New Brunswick' },
      { id: 'NL', name: 'NL - Newfoundland and Labrador' },
      { id: 'NT', name: 'NT - Northwest Territories' },
      { id: 'NS', name: 'NS - Nova Scotia' },
      { id: 'NU', name: 'NU - Nunavut' },
      { id: 'ON', name: 'ON - Ontario' },
      { id: 'PE', name: 'PE - Prince Edward Island' },
      { id: 'QC', name: 'QC - Quebec' },
      { id: 'SK', name: 'SK - Saskatchewan' },
      { id: 'YT', name: 'YT - Yukon Territory' },
    ],
  },
  {
    id: 4976,
    name: 'mexico',
    value: [
      { id: 'AG', name: 'AG - Aguascalientes' },
      { id: 'BC', name: 'BC - Baja California' },
      { id: 'BS', name: 'BS - Baja California Sur' },
      { id: 'CM', name: 'CM - Campeche' },
      { id: 'CS', name: 'CS - Chiapas' },
      { id: 'CH', name: 'CH - Chihuahua' },
      { id: 'MX', name: 'MX - Coahuila' },
      { id: 'CL', name: 'CL - Colima' },
      { id: 'DF', name: 'DF - Federal District' },
      { id: 'DG', name: 'DG - Durango' },
      { id: 'GT', name: 'GT - Guanajuato' },
      { id: 'GR', name: 'GR - Guerrero' },
      { id: 'HG', name: 'HG - Hidalgo' },
      { id: 'JA', name: 'JA - Jalisco' },
      { id: 'ME', name: 'ME - Mexico' },
      { id: 'MI', name: 'MI - Michoacán' },
      { id: 'MO', name: 'MO - Morelos' },
      { id: 'NA', name: 'NA - Nayarit' },
      { id: 'NL', name: 'NL - Nuevo León' },
      { id: 'OA', name: 'OA - Oaxaca' },
      { id: 'PU', name: 'PU - Puebla' },
      { id: 'QE', name: 'QE - Querétaro' },
      { id: 'QR', name: 'QR - Quintana Roo' },
      { id: 'SL', name: 'SL - San Luis Potosí' },
      { id: 'SI', name: 'SI - Sinaloa' },
      { id: 'SO', name: 'SO - Sonora' },
      { id: 'TB', name: 'TB - Tabasco' },
      { id: 'TM', name: 'TM - Tamaulipas' },
      { id: 'TL', name: 'TL - Tlaxcala' },
      { id: 'VE', name: 'VE - Veracruz' },
      { id: 'YU', name: 'YU - Yucatán' },
      { id: 'ZA', name: 'ZA - Zacatecas' },
    ],
  }
];

const getStateLongName = (state, country) => {
  return states.find
    (x => x.name.toLowerCase() === country?.toLowerCase())?.value.find
    (x => x.id.toLowerCase() === state?.trim().toLowerCase())?.name
};

export default {
  states,
  getStateLongName
};
