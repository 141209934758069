import React from 'react';
import PropTypes from 'prop-types';
import trophy from '../../assets/trophy.svg';
import moment from 'moment';
import './SearchResults.css';

const CompanyResult = (props) => {
    const { company, index, history } = props;
    const daysFromRO = company?.recentOpportunity?.opportunityId && moment().diff(moment(company?.recentOpportunity?.meetingDate).format('LLL'), 'days');
    const daysFromSA = moment().diff(moment
        (company?.lastSubstantialActivity?.startDate || company?.lastSubstantialActivity?.dueDate)
            .format('LLL'), 'days');

    return (
        <div className='Result' id={index} onClick={() => {
            history.push(`/company/${company.id}`)}}>
            <div className="resultContainer">
                <div className='companyResultHeader'>
                    <div className="companyResultName"> {company?.companyName} </div>
                    <div className="companyResultIcon">
                        {(company?.lastSubstantialActivity || company?.recentOpportunity) && <div className='pills'>
                        {company?.isClient && <div className='client'>
                            <sol-icon icon='person' size={24} style={{ color: '#840E2C', marginTop: '3px' }} />
                        </div>}
                        {daysFromSA <= 60 && company?.lastSubstantialActivity?.createdOn && (<div className='substantialActivity' onClick={(e) => {
                            history.push(`/company/${company.id}?tabType=activities`);
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <sol-icon icon='vital_signs' size={16} style={{ color: '#0C7BA1' }}/>
                        </div>)}
                        {daysFromRO <= 180 && (<div className='recentOpportunity' onClick={(e) => {
                            history.push(`/company/${company.id}?tabType=opportunities`);
                            e.preventDefault();
                            e.stopPropagation();

                        }}>
                            <img
                                className='trophy'
                                src={trophy}
                                alt="trophy"
                            />
                        </div>)}
                    </div>}
                    </div>
                </div>
                <div className='companyResultText1'>
                    {company?.country}
                </div>
                <div className='companyResultText2'>
                {company?.addressLine1 && `${company?.addressLine1}`}{company?.addressLine2 && `, ${company?.addressLine2}`}{company?.city && `, ${company?.city}`}{company?.state && `, ${company?.state}`}{company?.zipCode && `, ${company?.zipCode.split('-')[0]}`}
                </div>
            </div>
        </div>
    )
}

CompanyResult.propTypes = {
    company: PropTypes.shape({
        id: PropTypes.string,
        companyName: PropTypes.string,
        country: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        isClient: PropTypes.bool,
        recentOpportunity: PropTypes.shape({
            opportunityId: PropTypes.string,
            meetingDate: PropTypes.instanceOf(Date),
        }),
        lastSubstantialActivity: PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            dueDate: PropTypes.instanceOf(Date),
            createdOn: PropTypes.instanceOf(Date),
        }),
    }),
    index: PropTypes.number,
    history: PropTypes.object,
};

CompanyResult.defaultProps = {
    company: {
        id: '',
        companyName: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        isClient: false,
        recentOpportunity: {
            opportunityId: '',
            meetingDate: null,
        },
        lastSubstantialActivity: {
            startDate: null,
            dueDate: null,
            createdOn: null,
        },
    },
    index: 0,
    history: {},
};

export default CompanyResult;