import { BASE_URL, AuthHeaders } from "./config";
import axios from "axios";

export const getExpiringLeases = async (data) => {

  const response = await axios.get(
    `${BASE_URL}/leases/expiring?limit=${data.limit}&offset=${data.offset}`,
    {
      headers: {
        ...AuthHeaders()
      }
    }
  );
  return response.data;
}