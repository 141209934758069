export const ADD_EDIT_ACTIVITY = "ADD_EDIT_ACTIVITY";
export const ADD_EDIT_ACTIVITY_SUCCESS = "ADD_EDIT_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_BY_ID = "GET_ACTIVITY_BY_ID";
export const GET_ACTIVITY_BY_ID_SUCCESS = "GET_ACTIVITY_BY_ID_SUCCESS";
export const SORT_MY_ACTIVITIES = "SORT_MY_ACTIVITIES";
export const SORT_MY_ACTIVITIES_SUCCESS = "SORT_MY_ACTIVITIES_SUCCESS";

export function addEditActivity(data) {
    return {
        payload: data,
        type: ADD_EDIT_ACTIVITY,
    };
}

export function addEditActivitySuccess(data) {
    return {
        payload: data,
        type: ADD_EDIT_ACTIVITY_SUCCESS,
    };
}

export function getActivityById(data) {
    return {
        payload: data,
        type: GET_ACTIVITY_BY_ID,
    };
}

export function getActivityByIdSuccess(data) {
    return {
        payload: data,
        type: GET_ACTIVITY_BY_ID_SUCCESS,
    };
}

export function sortMyActivities(data) {
  return {
      payload: data,
      type: SORT_MY_ACTIVITIES,
  };
}

export function sortMyActivitiesSuccess(data) {
  return {
      payload: data,
      type: SORT_MY_ACTIVITIES_SUCCESS,
  };
}
