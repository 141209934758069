import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import './ContactDetails.css';
import { SolIcon, SolAvatar, SolDialog, SolDialogHeader } from "@solstice/sol-react";
import ContactDialogMobile from '../../components/ContactDialog/ContactDialogMobile';
import { ModalExitMobile } from '../../components/ModalExit/ModalExitMobile';
import { formatRoute } from '../../utils/formatRoute';
import { useSelector, useDispatch } from 'react-redux';
import { getContactDetails, editContactDetails } from "../../store/actions/Contact.actions";
import { getCompanyContacts } from "../../store/actions/Company.actions";
import HeaderMobile from "../Header/HeaderMobile";
import './ContactDetails.css';
import {formatFullName} from "../../utils/helpers";
import {Oval} from "react-loader-spinner";

const ContactDetailsMobile = (props) => {
    const { id } = useParams();
    const contact = useSelector(state => state.contact);
    const company = useSelector(state => state.company);
    const user = contact?.contactDetails?.result ? contact?.contactDetails?.result : contact?.contactDetails;
    const dispatch = useDispatch();
    const [userUpdateModal, setUserUpdateModal] = React.useState({})
    const [showUserStatusChangeModal, setShowUserStatusChangeModal] = React.useState(false)
    const [showLoader, setShowLoader] = useState(true);
    const COMPANY = '/company/:companyId';
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get('type');
    const companyId = searchParams.get('companyId');
    const ShowStatus = false;
    const prevContact = useRef("");

    React.useEffect(() => {
        dispatch(getContactDetails({ id, type }));
    }, [dispatch, id]);

    const [contactEditId, SetContactEditId] = useState(0);
    const [showContactDialog, SetShowContactDialog] = useState(false);
    const [exitModal, SetExitModal] = useState(false);

    React.useEffect(() => {
        if (showUserStatusChangeModal === true) {
            setShowLoader(false);
        }
    }, [showUserStatusChangeModal]);

    React.useEffect(() => {
       const currContact = contact?.contactDetails?.result ? contact?.contactDetails?.result : contact?.contactDetails;
       const _prevContact = prevContact?.current?.contactDetails?.result ? prevContact?.current?.contactDetails?.result : prevContact?.current?.contactDetails;
       
       if ((contact && prevContact.current && _prevContact && (currContact.id != _prevContact.id))) {
            setShowLoader(false);
        } 
        else {
            if (contact && contact.contactDetails && contact.contactDetails.id == id) {
                setShowLoader(false);
            } else {
                setShowLoader(true);
            }
        }
 
       prevContact.current = contact;
    }, [contact]);

    const editContactBtnClicked = (id) => {
        SetContactEditId(id);
        SetShowContactDialog(true);
    }

    const closeContactModalBtnClicked = () => {
        SetShowContactDialog(false);
    }

    const isJllContact = type?.includes('j');

    const changeStatus = (status) => {
        setShowUserStatusChangeModal(true);
        setUserUpdateModal((companyId && user?.id) ? {
            entityId: companyId,
            jllContactId: user?.id,
            status: status.toLowerCase() === 'active' ? 1 : 0,
            type: "company"
        } : {});
        user.isActive = status.toLowerCase() === 'active' ? true : false;
    }

    return (
        <>
            <div>
                <HeaderMobile history={props.history} showJllLogo={false} />

                {showLoader && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}> <Oval
                visible={true}
                height="40"
                width="40"
                color="#000000"
                secondaryColor={"gray"}
                ariaLabel="oval-loading"
                wrapperStyle={{zIndex: 1000000}}
                wrapperClass=""
            />
            </div>}

            {!showLoader && <div>
                <div className='display-inlineFlex-row header-background width-100pc padding-width-20px'>
                    <div className='avatarMain-div'>
                        {!isJllContact ?
                            <div className="companyContactCircle">{user?.firstName?.[0]}{user?.lastName?.[0]}</div> :
                            <div className="companyContactCircle">{`${user?.name?.split(', ')?.[1]?.[0]}${user?.name?.split(', ')?.[0]?.[0]}`}</div>}
                        <div style={{ margin: 'auto 0' }}>
                            {isJllContact ? <div
                                className='username-div'>{user?.name?.split(',')[1]} {user?.name?.split(',')[0]}</div> :
                                <div className='username-div'>{user?.firstName} {user?.lastName}</div>}
                            <div className='marketText-div'>
                                {!isJllContact && <RouterLink
                                    className='routerLink font-16-400'
                                    to={formatRoute(COMPANY, { companyId: user?.company?.id })}
                                >
                                    {(user?.company && user?.company?.displayName && user?.jobTitle?.trim()) ? `${user?.company?.displayName} - ` : user?.company?.displayName}
                                </RouterLink>}
                                {!isJllContact ?
                                    <div className='routerLink font-16-400'>
                                        {user?.jobTitle ? user?.jobTitle : ""}
                                    </div> :
                                    <div className='routerLink font-16-400'>
                                        {user?.title  ? user?.title  : ""}
                                    </div>}
                            </div>
                        </div></div>
                    {!isJllContact && <div style={{ paddingTop: '15px' }}>
                        <SolIcon className='edit-img' icon="edit" size="24"
                            onClick={() => {
                                editContactBtnClicked(user.id);
                            }}
                        />
                    </div>}
                </div>
                <div className='table-div-contactDet width-100pc'>
                    {type?.includes('j') && <div className='contactTable-row-div display-inlineFlex-row'>
                        <div className='contactTable-left-column'>
                            <p className='verticalCenter-para'>Business</p>
                        </div>
                        <div className='textAlign-right contactTable-right-column font-16-400'>
                            {user?.businessLine ?
                                <p className='verticalCenter-para'>
                                    <a className='textDecoration-none font-16-400 achortext-color' href={`tel: ${user?.officePhone}`}>
                                        {user?.businessLine}
                                    </a>
                                </p>
                                : '-'
                            }

                        </div>

                    </div>}
                    <div className='contactTable-row-div display-inlineFlex-row'>
                        <div className='contactTable-left-column'>
                            <p className='verticalCenter-para'>Address</p>
                        </div>
                        <div className='textAlign-right contactTable-right-column font-16-400'>
                            <p style={{ margin: '0px' }}>{user?.country?.displayName}</p>
                            <p style={{ margin: '0px' }} className='address-para font-16-400'>
                                {user?.addressLine1?.trim() ? `${user?.addressLine1}, ` : ''}
                                {user?.addressLine2 ? `${user?.addressLine2}` : ''}</p>
                            <p style={{ margin: '0px' }} className='address-para font-16-400'>
                                {user?.city?.trim() ? `${user?.city}, ` : ''}
                                {user?.state?.trim() ? `${user?.state}, ` : ''}
                                {user?.zipCode ? `${user?.zipCode}` : ''}</p>
                        </div>
                    </div>

                    {!isJllContact && <div className='contactTable-row-div display-inlineFlex-row'>
                        <div className='contactTable-left-column'>
                            <p className='verticalCenter-para'>
                                Business Phone
                            </p>
                        </div>
                        <div className='textAlign-right contactTable-right-column font-16-400'>
                            {user?.officePhone ?
                                <p className='verticalCenter-para'>
                                    <a className='textDecoration-none font-16-400 achortext-color'
                                        href={`tel: ${user?.officePhone}`}>
                                        {user?.officePhone}
                                    </a>
                                </p>
                                : '-'
                            }

                        </div>
                    </div>}

                    <div className='contactTable-row-div display-inlineFlex-row'>
                        <div className='contactTable-left-column'>
                            <p className='verticalCenter-para'>
                                {isJllContact ? 'Phone Number' : 'Mobile Phone'}
                            </p>
                        </div>
                        <div className='textAlign-right contactTable-right-column font-16-400'>

                            <p className='verticalCenter-para'>
                                <a className='textDecoration-none font-16-400 achortext-color' href={`tel: ${user?.mobilePhone}`}>
                                    {isJllContact ? user?.phoneNumber : user?.mobilePhone}
                                </a>
                            </p>

                        </div>
                    </div>

                    <div className='contactTable-row-div display-inlineFlex-row'>
                        <div className='contactTable-left-column'>
                            <p className='verticalCenter-para'>
                                Email
                            </p>
                        </div>
                        <div className='textAlign-right contactTable-right-column email-div font-16-400' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {user?.email ?
                                <>
                                    <a
                                        className='textDecoration-none font-16-400 achortext-color'
                                        href={`mailto: ${user?.email}`}
                                        id="emailId"
                                    >
                                        {user?.email}
                                    </a>
                                    <div
                                        style={{ paddingLeft: '10px', alignSelf: 'center' }}
                                        onClick={() => {
                                            const emailId = document.getElementById('emailId').innerText
                                            navigator.clipboard.writeText(emailId);
                                            alert("Copied email: " + emailId);
                                        }}>
                                        <SolIcon slot="icon" icon="content_copy" />
                                    </div>
                                </>
                                : '-'
                            }
                        </div>
                    </div>
                    {isJllContact && <div className='contactTable-row-div display-inlineFlex-row'>
                        <div className='contactTable-left-column'>
                            <p className='verticalCenter-para'>
                                Role
                            </p>
                        </div>
                        <div className='textAlign-right contactTable-right-column email-div font-16-400' style={{}}>
                            {user?.role ?
                                <div className='textDecoration-none font-16-400 achortext-color'>
                                    {user?.role}
                                </div>
                                : '-'
                            }
                        </div>
                    </div>}
                    <div className='passage-div'>
                        {user?.description || user?.importantInformation}
                    </div>
                    {ShowStatus && <div className='statusSection'>
                        <p className='contactTable-left-column'>
                            Status
                        </p>
                        <div className='statusSectionRadioPanel'>
                            <div className={'radioSelection'}> <input style={{accentColor: '#0C7BA1'}} type='radio' name='activeStatus' value='1' checked={isJllContact ? user?.isActive : user?.uiActive} onClick={() => { changeStatus('Active') }} /> <label>Active</label> </div>
                            <div><input style={{accentColor: '#0C7BA1'}} type='radio' name='activeStatus' value='0' checked={isJllContact ? !user?.isActive : !user?.uiActive} onClick={() => { changeStatus('Archive') }} /> <label>Archived</label></div>
                        </div>
                    </div>}
                </div>
                </div>}
                {showUserStatusChangeModal &&
                    <div>
                        <SolDialog floating-position="center"
                            id='ContactDetailsModal'
                            onSol-close={() => { setShowUserStatusChangeModal(false) }}
                        >
                            <SolDialogHeader slot="header"
                                label={`${user?.isActive ? "Activate" : "Archive"} JLL Contact`}
                                className='dialogHeader'
                            ></SolDialogHeader>
                            <div slot="body">
                                <div className='add_circle-div'>
                                    <div className='confirmToActivate'>
                                        {`Please confirm to ${user?.isActive ? "activate" : "archive"} the JLL contact from ${company?.companyInformation?.companyName}`}
                                    </div>
                                </div>
                                <div className='buttonClass margin-top-20'
                                    style={{ background: '#01151C', color: '#FFFFFF' }}
                                    onClick={() => {
                                        setShowUserStatusChangeModal(false)
                                    }
                                    }
                                >
                                    Cancel
                                </div>
                                <div className='buttonClass margin-top-12'
                                    style={{ background: '#01151C', color: '#FFFFFF' }}
                                    onClick={() => {
                                        dispatch(editContactDetails(userUpdateModal, {
                                            type: "jllcontacts",
                                            companyId: companyId,
                                            limit: 15,
                                            offset: 0,
                                            isActive: true
                                        }));
                                        setShowUserStatusChangeModal(false);
                                    }}
                                >
                                    Confirm
                                </div>
                            </div>
                        </SolDialog>
                    </div >
                }
                {showContactDialog &&
                    <ContactDialogMobile
                        SetExitModal={SetExitModal}
                        closeContactModalBtnClicked={() => {
                            closeContactModalBtnClicked()
                        }}
                        regarding={user}
                        contactEditId={contactEditId}
                    />
                }
                {exitModal &&
                    <ModalExitMobile
                        SetExitModal={SetExitModal}
                        closeActivityModalBtnClicked={() => {
                        }}
                        closeContactModalBtnClicked={() => {
                            closeContactModalBtnClicked()
                        }}
                    />
                }
            </div>

        </>
    )
}

ContactDetailsMobile.propTypes = {
    history: PropTypes.object,
};

ContactDetailsMobile.defaultProps = {
    history: {},
};

export default ContactDetailsMobile;