import axios from "axios";
import aeroConfig from "../config/aeroConfig";
// import { handleErrors, handleOktaErrors } from "utils/errorHandler";
import * as TokenManager from "../utils/tokenManager";

export const ApiUri = aeroConfig.pitch_builder_api || `${process.env.REACT_APP_PITCH_BUILDER_API_URL}/`;
export const ApiKey = aeroConfig.pitch_builder_api_key || `${process.env.REACT_APP_PITCH_BUILDER_API_KEY}`;
export const BASE_URL =
    aeroConfig.jll_url || process.env.REACT_APP_JLL_BASE_URL;

export const OKTA_TOKEN_STORAGE = 'okta-token-storage';

export const OdsAzureSpidercrmApiAPIMToken =
    aeroConfig.OdsAzureSpidercrmApiAPIMToken || process.env.REACT_APP_OdsAzureSpidercrmApiAPIMToken;
export const AuthHeaders = () => {
    let headers = {
        Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        "subscription-key": OdsAzureSpidercrmApiAPIMToken
    };

    return headers;
};

// HTTP Client for needs-assessment api
export const httpClient = (baseUrl = ApiUri) => {
    baseUrl = baseUrl.replace("/portfolio", "");
    const client = axios.create({
        baseURL: baseUrl,
        headers: AuthHeaders()
    });

    client.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response) {
                console.log(error.response);
            }
            return Promise.reject(error);
        }
    );

    return client;
};

// HTTP Client for Okta
export const oktaHttpClient = (baseUrl) => {
    const client = axios.create({
        baseURL: baseUrl
    });


    client.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response) {
                console.log(error.response);
            }
            return Promise.reject(error);
        }
    );

    return client;
};

export const getUser = () => {
    try {
        const oktaSession = localStorage.getItem(OKTA_TOKEN_STORAGE);
        const token = JSON.parse(oktaSession || "");
        //Get UPN from Access Token for One JLL
        return token.accessToken.claims.sub;
        //return idToken.idToken.claims.email;
    } catch {
        return undefined;
    }
};
