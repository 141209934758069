import './App.css';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SearchContainerMobile from "./components/SearchContainer/SearchContainerMobile";
import ErrorContainerMobile from './components/ErrorContainer/ErrorContainerMobile'
import '@solstice/sol-stencil/dist/sol-stencil/sol-stencil.css';
import { LoginCallback, Security, SecureRoute, withOktaAuth } from "@okta/okta-react";
import { OktaAuth as LocalOktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import * as TokenManager from "./utils/tokenManager";
import CompanyPage from './pages/CompanyPage/CompanyPage'
import ContactDetailsMobile from './components/ContactDetailsContainer/ContactDetailsMobile'
import OktaConfig from "./config/OktaConfig";
import EchelonHomePage from "./pages/EchelonHomePage/EchelonHomePage";
import { useIdleTimer } from 'react-idle-timer'
import React from "react";
import {deleteLocalStorageValue, isTokenTimeElapsed, setLocalStorageValue} from "./utils/tokenManager";
import moment from "moment";
import AeroHomePage from "./pages/AeroHomePage/AeroHomePage";

const OktaAuth = new LocalOktaAuth({
    clientId: OktaConfig.client_id,
    issuer: OktaConfig.issuer,
    redirectUri: OktaConfig.redirect_uri,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    postLogoutRedirectUri: window.location.href,
    pkce: true,
    disableHttpsCheck: true,
});

const history = createBrowserHistory();

function App() {

    const triggerLogin = async () => {
        await OktaAuth.signInWithRedirect();
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        // eslint-disable-next-line no-restricted-globals
        location.href = toRelativeUrl(originalUri || '/', window.location.origin);
    };

    const onIdle = () => {
        console.log('User is idle');
    }

    const onActive = async () => {
        let idleTime= moment(getLastIdleTime());
        if(isTokenTimeElapsed(moment(idleTime))) {
            await setLocalStorageValue('lastLocationPath', window.location.pathname);
            deleteLocalStorageValue('okta-token-storage');
            await triggerLogin();
        }
    }

    const customAuthHandler = async () => {
        const previousAuthState = OktaAuth.authStateManager.getPreviousAuthState();

        if (!previousAuthState || !previousAuthState.isAuthenticated) {
            // App initialization stage
            await triggerLogin();
        } else {
            // Ask the user to trigger the login process during token autoRenew process
        }
    }

    if(TokenManager.isTokenExpired()) {
        console.log('customAuthHandler called');
    }

   const { getLastIdleTime } = useIdleTimer({
        onIdle,
        onActive,
        timeout: 10000,
        throttle: 500
    });



    return (
        <div className="App">
            <Router history={history}>
                <Security
                    oktaAuth={OktaAuth}
                    onAuthRequired={customAuthHandler}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <Switch>
                        <Route path={"/auth"} exact={true} component={LoginCallback} />

                        <SecureRoute
                            exact
                            path={"/"}
                            history={history}
                            component={withOktaAuth(AeroHomePage)}
                        />
                        <SecureRoute path={"/search"} history={history} component={withOktaAuth(SearchContainerMobile)} />
                        <SecureRoute path={"/error"} component={withOktaAuth(ErrorContainerMobile)} />
                        <SecureRoute path={"/echelon"} component={withOktaAuth(EchelonHomePage)} />
                        <SecureRoute exact path={"/company/:id"} history={history} component={withOktaAuth(CompanyPage)} />
                        <SecureRoute exact path={"/contact/:id"} history={history} component={withOktaAuth(ContactDetailsMobile)} />
                        <Route path="/auth" exact component={LoginCallback} />
                    </Switch>
                </Security>
            </Router>
        </div>
    );
}

export default App;
