import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ContactsContainer.css';
import CompanyContactsMobile from '../../CompanyContacts/CompanyContactsMobile'
import { setGA4Data } from "../../../utils/googleAnalytics";
import CompanyDetailsHeader from "../../CompanyDetailsHeader/CompanyDetailsHeader";

class ContactsContainerMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedContactHeaderTab: 'Active'
        };
        this.selectedContactHeaderTabChange = this.selectedContactHeaderTabChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    componentDidMount() {
        const { getCompanyContacts, companyId, type, company } = this.props;
        const { selectedContactHeaderTab } = this.state;
        setGA4Data('CompDetailsContact', {
            companyID: companyId
        });
        if(type === 'contacts') {
            if (company.companyContactsActive?.elements?.length === 0) {
                getCompanyContacts({
                    type: type,
                    companyId: companyId,
                    limit: 15,
                    offset: 0,
                    isActive: selectedContactHeaderTab === 'Active'
                });

            }
        } else {
            if (company.companyJllContactsActive?.elements?.length === 0) {
                getCompanyContacts({
                    type: type,
                    companyId: companyId,
                    limit: 15,
                    offset: 0,
                    isActive: selectedContactHeaderTab === 'Active'
                });

            }
        }
    }

    selectedContactHeaderTabChange = (value) => {
        const { company, type } = this.props;
        const { selectedContactHeaderTab } = this.state;

        this.setState({ selectedContactHeaderTab: value });
        if(type === 'contacts') {
            if((company.companyContactsActive?.elements?.length === 0 && value.toLowerCase() === 'active') ||
                (company.companyContactsArchive?.elements?.length === 0 && value.toLowerCase() === 'archive')) {
                this.handleNext(value);
            }
        } else {
            if((company.companyJllContactsActive?.elements?.length === 0 && value.toLowerCase() === 'active') ||
                (company.companyJllContactsArchive?.elements?.length === 0 && value.toLowerCase() === 'archive')) {
                this.handleNext(value);
            }
        }

    }
    handleNext(value) {
        const { selectedContactHeaderTab } = this.state;
        const { type } = this.props;
        const tabValue = value || selectedContactHeaderTab;
        const { getCompanyContacts, companyId, company } = this.props;
        let allowCall = {}
        if (type === 'contacts') {
            allowCall = {
                'Active':   company.companyContactsActive?.elements?.length < company.companyContactsActive?.activeTotal,
                'Archive': company.companyContactsArchive?.elements?.length < company.companyContactsArchive?.inactiveTotal,
            }
        } else {
            allowCall = {
                'Active':   company.companyJllContactsActive?.elements?.length < company.companyJllContactsActive?.activeTotal,
                'Archive': company.companyJllContactsArchive?.elements?.length < company.companyJllContactsArchive?.inactiveTotal,
            }
        }

        if(allowCall[tabValue]) {
            let offset =  type === 'contacts' ?
                    tabValue.toLowerCase() === 'active' ? parseInt(company.companyContactsActive?.elements?.length): parseInt(company.companyContactsArchive?.elements?.length):
                tabValue.toLowerCase() === 'active' ? parseInt(company.companyJllContactsActive?.elements?.length): parseInt(company.companyJllContactsArchive?.elements?.length);
            getCompanyContacts({
                type: type,
                companyId: companyId,
                limit: 15,
                offset,
                isActive: tabValue === 'Active' });

        }
    }


    render() {
        const { selectedContactHeaderTab } = this.state;
        const { getCompanyContacts, companyId, company, history, type } = this.props;

        return (
            <div>
                <CompanyDetailsHeader company={company?.companyInformation}/>
                <div className='header-highlight-div header-div-FeedContainerMobile width-100pc'>
                    <div role="button" onClick={() => { this.selectedContactHeaderTabChange('Active') }}
                        className={'header-text ' +
                            (selectedContactHeaderTab.toLowerCase() === 'active' ?
                                'highlight-header-text' :
                                'nonHighlight-header-text')}
                    >
                        {type === 'contacts' && company.companyContactsActive.activeTotal && `Active (${company.companyContactsActive.activeTotal})`}
                        {type !== 'contacts' && company.companyJllContactsActive.activeTotal && `Active (${company.companyJllContactsActive.activeTotal})`}
                    </div>
                    <div role="button" onClick={() => { this.selectedContactHeaderTabChange('Archive') }}
                        className={'header-text ' +
                            (selectedContactHeaderTab.toLowerCase() === 'archive' ?
                                'highlight-header-text' :
                                'nonHighlight-header-text')}
                    >
                        {type === 'contacts' && company.companyContactsArchive.inactiveTotal && `Archived (${company.companyContactsArchive.inactiveTotal})`}
                        {type !== 'contacts' && company.companyJllContactsArchive.inactiveTotal && `Archived (${company.companyJllContactsArchive.inactiveTotal})`}
                    </div>
                </div>
                <CompanyContactsMobile
                    contactsActive={type === 'contacts' ? company.companyContactsActive: company.companyJllContactsActive}
                    contactsArchive={type === 'contacts' ? company.companyContactsArchive: company.companyJllContactsArchive}
                    selectedContactHeaderTab={selectedContactHeaderTab}
                    getCompanyContacts={getCompanyContacts}
                    companyId={companyId}
                    company={company}
                    history={history}
                    handleNext={this.handleNext}
                    type={type}
                />
            </div>
        );
    }
}

ContactsContainerMobile.propTypes = {
    getCompanyContacts: PropTypes.func,
    companyId: PropTypes.string,
    type: PropTypes.string,
    company: PropTypes.object,
    history: PropTypes.object,
};

ContactsContainerMobile.defaultProps = {
    getCompanyContacts: () => {},
    companyId: '',
    type: '',
    company: {},
    history: {},
};

export default ContactsContainerMobile;