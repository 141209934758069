import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DetailsContainer.css';
import FeedContainerMobile from './FeedContainer/FeedContainerMobile'
import ContactsContainerMobile from './ContactsContainer/ContactsContainerMobile'
import CompanyDetailsMobile from '../CompanyDetails/CompanyDetailsMobile'

class DetailsContainerMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        let {
            tabSelected,
            company,
            contacts,
            getCompanyFeed,
            companyId,
            editActivityBtnClicked,
            closeActivityModalBtnClicked,
            getCompanyContacts,
            history
            } = this.props;

        return (
            <div className='DetailsContainerMobile-maindiv'>
                {tabSelected.toLowerCase()?.includes('feed') &&
                    <FeedContainerMobile
                        companyId={companyId}
                        getCompanyFeed={getCompanyFeed}
                        company={company}
                    closeActivityModalBtnClicked={() => {closeActivityModalBtnClicked()}}
                    editActivityBtnClicked={(id, type) => { editActivityBtnClicked(id, type) }}
                    />}
                {tabSelected.toLowerCase() === 'contacts' &&
                    <ContactsContainerMobile
                        history={history}
                        contacts={contacts}
                        companyId={companyId}
                        getCompanyContacts={getCompanyContacts}
                        company={company}
                        type={'contacts'}

                    />}
                {tabSelected.toLowerCase()?.includes('about') &&
                    <CompanyDetailsMobile companyId={companyId} company={company?.companyInformation} />}

                {tabSelected.toLowerCase()?.includes('jll') &&
                    <ContactsContainerMobile
                        history={history}
                        contacts={contacts}
                        companyId={companyId}
                        getCompanyContacts={getCompanyContacts}
                        company={company}
                        type={'jllcontacts'}
                    />}
            </div>
        );
    }
}

DetailsContainerMobile.propTypes = {
    tabSelected: PropTypes.string,
    company: PropTypes.object,
    contacts: PropTypes.array,
    getCompanyFeed: PropTypes.func,
    companyId: PropTypes.string,
    editActivityBtnClicked: PropTypes.func,
    closeActivityModalBtnClicked: PropTypes.func,
    getCompanyContacts: PropTypes.func,
    history: PropTypes.object,
};

DetailsContainerMobile.defaultProps = {
    tabSelected: '',
    company: {},
    contacts: [],
    getCompanyFeed: () => {},
    companyId: '',
    editActivityBtnClicked: () => {},
    closeActivityModalBtnClicked: () => {},
    getCompanyContacts: () => {},
    history: {},
};

export default DetailsContainerMobile;