export const autoSuggestScrollToElement = (id) => {
    document.getElementById(id).scrollIntoView();
    setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    },500)
}

export const formatFullName = (fullName) => {
    if (fullName && fullName.includes(',')) {
        const name = fullName.split(',');
        const firstName = name[1].trim();
        const lastName = name[0].trim();

        return `${firstName} ${lastName}`;
    }
}