import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './EchelonHomePage.css';
import HeaderDesktop from '../../components/Echelon/Header/HeaderDesktop';
import { connect } from "react-redux";
import BlogEntryContainerDesktop from "../../components/Echelon/BlogEntryContainer/BlogEntryContainerDesktop";
import BlogListContainerDesktop from "../../components/Echelon/BlogListContainer/BlogListContainerDesktop";

const EchelonHomePage = (props) => {
  const { history } = props;
  const [showAddBlog, setShowAddBlog] = useState(false);
  const [editBlog, setEditBlog] = useState(null);
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    document.title = 'Echelon';
  }, []);

  const handleEditBlog = (entry) => {
    setEditBlog(entry);
    setShowAddBlog(true);
  }

  return (
    <div className='echelonHomepageContainer' >
      <HeaderDesktop
        history={history}
        showJllLogo={false}
        searchParams={searchParams}
      />
      <div className='echelonBlogDetailsContainer'>
        <BlogEntryContainerDesktop
          setEditBlog={setEditBlog}
          editBlog={editBlog}
          setSearchParams={setSearchParams}
        />
        <BlogListContainerDesktop
          showAddBlog={showAddBlog}
          setShowAddBlog={setShowAddBlog}
          handleEditBlog={handleEditBlog}
          setEditBlog={setEditBlog}
          editBlog={editBlog}
        />
      </div>
    </div>
  )
}


EchelonHomePage.propTypes = {
  history: PropTypes.object,
};

EchelonHomePage.defaultProps = {
  history: {},
};

function mapStateToProps(state) {
  if (state.company) {
    return {
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EchelonHomePage);
