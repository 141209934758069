import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchContainer.css';
import SearchResultsSectionMobile from '../SearchResultsSection/SearchResultsSectionMobile';
import { setGA4Data } from "./../../utils/googleAnalytics";

const SearchContainerMobile = (props) => {

    const { searchDetails, history } = props;
    const [buttonSelected, SetButtonSelected] = useState('companies');
    const [searchText, setSearchText] = useState('');
    const [showTextClear, SetShowTextClear] = useState(false);
    const [handleSearchClicked, SetHandleSearchClicked] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const isDuplicateSearch = (buttonSelected === 'contacts' && searchDetails?.contactSearchTerm === searchText) || (buttonSelected === 'companies' && searchDetails?.companySearchTerm === searchText);
    const searchParams = new URLSearchParams(window.location.search);
    const tabType = searchParams.get('tabType');
    const hideMobileKeyboardOnReturn = (element) => {
        const key = element.code || element.keyCode;
        if (key === 'Enter' || key === 13) {
            document.getElementById('searchInput').blur();
        }
    };

    React.useEffect(() => {
        if (tabType?.includes('contacts')) {
            SetButtonSelected('contacts');
        }
    }, [])
    const handleSearch = (resetFlag, type, event) => {
        const seacrhedType = type || buttonSelected;
        SetHandleSearchClicked(true);
        if (searchText && searchText.length > 0) {
            setGA4Data(seacrhedType, {
                searchedEntity: seacrhedType,
                searchedText: searchText
            });
            SetShowTextClear(searchText ? true : false);
            let offset = 0;
            offset = 30 * (type === 'companies' ? (props.searchDetails?.companyPageNumber || 1) : (props.searchDetails?.contactPageNumber || 1));
            if (resetFlag) {
                setPageNumber(1);
                offset = 0;
            }
            props.searchActions.getSearchDetails({
                type: type || buttonSelected,
                searchText,
                pageNo: !resetFlag ? (type === 'companies' ? (props.searchDetails?.companyPageNumber || 0) + 1 : (props.searchDetails?.contactPageNumber || 0) + 1) : 1,
                offset: offset
            });
            setPageNumber(resetFlag ? 2 : pageNumber + 1);
            if (event) {
                event.stopPropagation();

            }
        }
    }
    return (
        <div>
            <div className='SearchHeader' id='SearchHeader'>
                <div className='SearchHeaderText'>Search</div>
                <div className='SearchHeaderButtons'>
                    <div className='SearchHeaderButton'
                        style={{ background: buttonSelected === 'companies' && '#01151C', color: buttonSelected === 'companies' && '#FFFFFF' }}
                        onClick={(e) => {
                            setPageNumber(1)
                            if (!searchDetails?.searchResults?.companiesResults || searchDetails?.searchResults?.companiesResults?.length <= 0 || searchText !== searchDetails?.companySearchTerm) {
                                handleSearch(true, 'companies', e, props.searchDetails.companyOffset, props.searchDetails.companyPageNumber);
                            }
                            SetButtonSelected('companies');
                        }}>
                        <sol-icon icon='home_work' style={{ color: buttonSelected === 'companies' ? '#32A4CA' : '#01151C' }} />Companies
                    </div>
                    <div className='SearchHeaderButton'
                        style={{ background: buttonSelected === 'contacts' && '#01151C', color: buttonSelected === 'contacts' && '#FFFFFF' }}
                        onClick={(e) => {
                            setPageNumber(1);
                            if (!searchDetails?.searchResults?.contactsResults || (searchDetails?.searchResults?.contactsResults?.length <= 0) || (searchText !== searchDetails?.contactSearchTerm)) {
                                handleSearch(true, 'contacts', e);
                            }
                            SetButtonSelected('contacts')
                        }}>
                        <sol-icon icon='person_search' style={{ color: buttonSelected === 'contacts' ? '#32A4CA' : '#01151C' }} />Contacts
                    </div>
                </div>
                <div className='SearchHeaderMobileSearchContainer'>
                    {searchDetails.searchResults.showBackButton && <sol-icon size="24" style={{ color: '#0C7BA1', marginRight: '8px' }} icon="arrow_back" onClick={() => {
                        setSearchText('');
                        SetHandleSearchClicked(false);
                        props.searchActions.removeSearchDetails()
                    }} />}
                    <div className="SearchHeaderInput">
                        <input
                            style={{ width: searchDetails.searchResults.showBackButton ? '62vw' : '70vw' }}
                            className="SearchHeaderMobileSearch"
                            id={'searchInput'}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            placeholder="Search..."
                            type="text"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    if (!isDuplicateSearch) {
                                        handleSearch(true);
                                    }
                                    hideMobileKeyboardOnReturn(event);
                                }

                            }}
                        />
                        <sol-icon size="24" style={{ color: '#0C7BA1', marginLeft: '-40px' }} icon="search" onClick={(event) => {
                            if (!isDuplicateSearch) {
                                handleSearch(true);
                            }
                            hideMobileKeyboardOnReturn(event);
                        }} />
                        <sol-icon size="24" style={{ display: !showTextClear && 'none', marginLeft: '-50px' }} icon="close" onClick={() => { setSearchText(''); SetHandleSearchClicked(false); SetShowTextClear(false) }} />
                    </div>
                </div>
            </div>
            <SearchResultsSectionMobile
                handleSearch={handleSearch}
                searchText={searchText}
                type={buttonSelected}
                history={history}
                companiesCount={searchDetails.companiesCount}
                contactsCount={searchDetails.contactsCount}
                isLoading={searchDetails?.isLoading}
                searchDetails={searchDetails?.searchResults}
                handleSearchClicked={handleSearchClicked}
            />
        </div>

    )
}

SearchContainerMobile.propTypes = {
    searchDetails: PropTypes.shape({
        companySearchTerm: PropTypes.string,
        contactSearchTerm: PropTypes.string,
        companyPageNumber: PropTypes.number,
        contactPageNumber: PropTypes.number,
        companyOffset: PropTypes.number,
        searchResults: PropTypes.shape({
            companiesResults: PropTypes.array,
            contactsResults: PropTypes.array,
            showBackButton: PropTypes.bool,
        }),
        companiesCount: PropTypes.number,
        contactsCount: PropTypes.number,
        isLoading: PropTypes.bool,
    }),
    history: PropTypes.object,
    searchActions: PropTypes.shape({
        getSearchDetails: PropTypes.func,
        removeSearchDetails: PropTypes.func,
    }),
};

SearchContainerMobile.defaultProps = {
    searchDetails: {
        companySearchTerm: '',
        contactSearchTerm: '',
        companyPageNumber: 0,
        contactPageNumber: 0,
        companyOffset: 0,
        searchResults: {
            companiesResults: [],
            contactsResults: [],
            showBackButton: false,
        },
        companiesCount: 0,
        contactsCount: 0,
        isLoading: false,
    },
    history: {},
    searchActions: {
        getSearchDetails: () => {},
        removeSearchDetails: () => {},
    },
};

export default SearchContainerMobile;