import React from 'react';
import moment from "moment";
import leaseExpirationsEmpty from '../../assets/leaseExpirationsEmpty.svg';
import './LeaseExpirations.css';

const LeaseExpirations = (props) => {
  const { leaseExpirations } = props;
  let leaseExpirationsPreview = leaseExpirations;
  if (leaseExpirations?.length > 4) {
    leaseExpirationsPreview = leaseExpirations.slice(0, 4);
  }

  return (
    <div className='leaseExpirations' id='leaseExpirations'>
      {leaseExpirationsPreview?.length === 0 ?
        <div className='leaseExpirationsEmpty'>
          <img style={{ paddingBottom: '20px' }} src={leaseExpirationsEmpty} />
          <br />
          <text>Close deals in Dealio to start tracking lease expirations here</text>
        </div> :
        leaseExpirationsPreview?.map((lease) => {
          return (
            <div style={{ paddingBottom: '15px' }}>
              <div className='leaseExpirationsTopText'>
                <a style={{ color: '#01151D', fontWeight: '500' }} href={`company/${383968}`}>
                  {lease.clientName}
                </a>
                <text className='leaseExpirationsDate'>
                  {moment(lease.endDate).format('ll')}
                </text>
              </div>
                {lease.address}
            </div>
          )
        })}
    </div>
  )
};

export default LeaseExpirations;