import React from 'react';
import { SolButton } from "@solstice/sol-react";
import './HomePageComponent.css';

const HomePageComponentDesktop = ({ children, ...props }) => {
  const { data } = props;
  return (
    <div className='HomePageComponent' id='HomePageComponent'>
      <div className='HomePageComponentHeader'>
        <p1>{props.header}</p1>
        {data?.length > 0 &&
          <SolButton
            label={'View more'}
            variant={'outlined'}
            size={'medium'}
            onSol-click={() => {
              // TO-DO
            }}
          >
            View more
          </SolButton>}
      </div>
      <div className='body' {...props}>
        {children}
      </div>
    </div>
  )
}

export default HomePageComponentDesktop;