import api from "../../api/index";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {GET_SEARCH_DETAILS, getSearchDetailsFailed, getSearchDetailsSuccess} from "../actions/Search.actions";

export function* getSearchCompanyAndContactSaga(action) {
    try {
        const response = yield call(
            api.searchApi.searchCompanyAndContact,
            action.payload
        );
        yield put(getSearchDetailsSuccess(response));
    } catch (e) {
        yield put(getSearchDetailsFailed({}));

        console.log(e);
    }
}

export function* watchSearchCompanyAndContact() {
    yield takeEvery(GET_SEARCH_DETAILS, getSearchCompanyAndContactSaga);
}

export default function* searchSagas() {
    yield all([
        watchSearchCompanyAndContact()
    ]);
}