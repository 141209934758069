import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    EDIT_CONTACT_DETAILS,
    editContactDetailsSuccess,
    GET_CONTACT_DETAILS,
    getContactDetailsSuccess,
    ADD_EDIT_CONTACT,
    addEditContactSuccess,
} from "../actions/Contact.actions";
import {getCompanyFeedSaga, getCompanyContactDetailsSaga} from "./Company.sagas";



export function* getAddContactSaga(action) {
    try {
        const response = yield call(
            api.contactApi.fetchContactById,
            action.payload
        );
        yield put(getContactDetailsSuccess(response));
    } catch (e) {
        console.log(e);
    }
}
export function* editContactSaga(action) {
    try {
        const response = yield call(
            api.contactApi.editContactDetails,
            action.payload
        );
        yield call(getCompanyContactDetailsSaga, {
            companyId: action.companyId,
            isActive: true,
            limit: action.limit,
            offset: action.offset,
            type: action.type
        });
        yield call(getCompanyContactDetailsSaga, {
            companyId: action.companyId,
            isActive: false,
            limit: action.limit,
            offset: action.offset,
            type: action.type
        });
        yield put(editContactDetailsSuccess(response));
    } catch (e) {
        console.log(e);
    }
}
export function* addEditContactSaga(action) {
    try {
        if(action?.payload?.cb) {
            action?.payload?.cb(true);
        }
        const response = yield call(
            api.contactApi.addEditContact,
            action.payload?.contactData
        );
        yield put(addEditContactSuccess(response));
        if (action?.payload?.contactData.id ) {
            yield call(getAddContactSaga, {payload: { id: action?.payload?.contactData?.id?.toString(), type: 'c'}, type: "GET_CONTACT_DETAILS"});
        }
        yield call(getCompanyFeedSaga, {payload: { id: action?.payload?.contactData?.companyId?.toString(), limit: 15, offset: 1, refreshFeed: true }});
        if(action?.payload?.cb) {
            action?.payload?.cb(false);
        }
        yield call(getCompanyContactDetailsSaga, {
            payload: {
                companyId: action.payload.contactData.companyId,
                isActive: true,
                limit: 15,
                offset: 0,
                type: 'contacts',
                reset: true,
            }
        });
        yield call(getCompanyContactDetailsSaga, {
            payload: {
                companyId: action.payload.contactData.companyId,
                isActive: false,
                limit: 15,
                offset: 0,
                type: 'contacts',
                reset: true,
            }
        });
    } catch (e) {
        console.log(e, 'error');
    }
}

export function* watchAddContact() {
    yield takeEvery(GET_CONTACT_DETAILS, getAddContactSaga);
}
export function* watchEditContact() {
    yield takeEvery(EDIT_CONTACT_DETAILS, editContactSaga);
}
export function* watchAddEditContact() {
    yield takeEvery(ADD_EDIT_CONTACT, addEditContactSaga);
}

export default function* contactSagas() {
    yield all([
        watchAddContact(),
        watchEditContact(),
        watchAddEditContact(),
    ]);
}