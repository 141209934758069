import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './CompanyPage.css';
import HeaderMobile from '../../components/Header/HeaderMobile';
import CompanyFooterMobile from '../../components/CompanyFooter/CompanyFooterMobile';
import {bindActionCreators} from "redux";
import * as CompanyActions from "../../store/actions/Company.actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {ModalExitMobile} from '../../components/ModalExit/ModalExitMobile';
import DetailsContainerMobile from '../../components/DetailsContainer/DetailsContainerMobile'
import CompanyPageModalMobile from '../../components/CompanyPageModal/CompanyPageModalMobile'
import ActivityDialogMobile from '../../components/ActivityDialog/ActivityDialogMobile';
import ContactDialogMobile from '../../components/ContactDialog/ContactDialogMobile';
import {Oval} from "react-loader-spinner";
import { setGA4Data } from "../../utils/googleAnalytics";

const CompanyPage = (props) => {
    const {id} = useParams();
    const {companyActions, company, history} = props;
    const searchParams = new URLSearchParams(window.location.search);
    const tab = searchParams.get('tab');
    const tabType = searchParams.get('tabType');


    React.useEffect(() => {
        companyActions.getCompanyDetails({id});
        companyActions.getCompanyFeed({
            id,
            limit: 15,
            offset: 1,
            opportunitiesOnly: tabType && (tabType.toLowerCase() === 'opportunities' ? true : undefined),
            activitiesOnly: tabType && (tabType.toLowerCase() === 'activities' ? true : undefined)
        });
    }, [id, companyActions]);


    const [showAddDialog, SetShowAddDialog] = useState(false);
    const [showActivityDialog, SetShowActivityDialog] = useState(false);
    const [showContactDialog, SetShowContactDialog] = useState(false);
    const [activityDialogMode, SetActivityDialogMode] = useState('add');
    const [activityEditId, SetActivityEditId] = useState(0);
    const [activityType, SetActivityType] = useState('phoneCall');
    const [tabSelected, SetTabSelected] = useState('Feed');
    const [exitModal, SetExitModal] = useState(false);

    React.useEffect(() => {
        if(tab)
            SetTabSelected(tab);
    }, [tab]);

    const addBtnClicked = () => {
        SetShowAddDialog(true)
    }

    const closeBtnClicked = () => {
        SetShowAddDialog(false)
    }

    const closeActivityModalBtnClicked = () => {
        SetShowActivityDialog(false)
    }

    const closeContactModalBtnClicked = () => {
        SetShowContactDialog(false);
    }

    const addActivityBtnClicked = () => {
        SetShowAddDialog(false);
        SetActivityDialogMode('add');
        SetShowActivityDialog(true);
        SetActivityEditId(0);
    }
    const editActivityBtnClicked = (id, type) => {
        SetActivityDialogMode('edit');
        SetShowActivityDialog(true);
        SetActivityType(type);
        SetActivityEditId(id);
    }

    const addContactBtnClicked = () => {
        SetShowAddDialog(false);
        SetShowContactDialog(true);
    }

    const onTabSelectedChanged = (value) => {
        if (value === 'JLL Contacts') {
            setGA4Data('CompDetailsJLLContacts', { companyID: id });
        }
        SetTabSelected(value)
    }

    return (
        <div className='CompanyPage' id='CompanyPage'>
            <HeaderMobile
                history={history}
                showJllLogo={false}
                clearCompanyDetails={companyActions.clearCompanyDetails}
            />
            <DetailsContainerMobile
                history={history}
                getCompanyFeed={companyActions.getCompanyFeed}
                getCompanyContacts={companyActions.getCompanyContacts}
                companyId={id}
                tabSelected={tabSelected}
                company={company}
                contacts={[]}
                closeActivityModalBtnClicked={() => {
                    closeActivityModalBtnClicked()
                }}
                closeContactModalBtnClicked={() => {
                    closeContactModalBtnClicked()
                }}
                editActivityBtnClicked={(id, type) => {
                    editActivityBtnClicked(id, type)
                }}
            />
            {showActivityDialog &&
                <ActivityDialogMobile
                    SetExitModal={SetExitModal}
                    closeActivityModalBtnClicked={() => {
                        closeActivityModalBtnClicked()
                    }}
                    // regarding={company?.companyInformation}
                    regarding={{
                        id: company?.companyInformation.id,
                        displayName: company?.companyInformation.companyName,
                        type: "company"
                    }}
                    activityEditId={activityEditId}
                    activityType={activityType}
                    editMode={activityDialogMode}
                />
            }
            {showContactDialog &&
                <ContactDialogMobile
                    SetExitModal={SetExitModal}
                    closeContactModalBtnClicked={() => {
                        closeContactModalBtnClicked()
                    }}
                    regarding={company.companyInformation}
                    contactEditId={null}
                />
            }
            {exitModal &&
                <ModalExitMobile
                    SetExitModal={SetExitModal}
                    closeActivityModalBtnClicked={() => {
                        closeActivityModalBtnClicked()
                    }}
                    closeContactModalBtnClicked={() => {
                        closeContactModalBtnClicked()
                    }}
                />
            }

            <CompanyFooterMobile
                showAddDialog={showAddDialog}
                tabSelected={tabSelected}
                onTabSelectedChanged={onTabSelectedChanged}
                addBtnClicked={() => {
                    addBtnClicked()
                }}
            />
            {showAddDialog &&
                <CompanyPageModalMobile
                    closeBtnClicked={() => {
                        closeBtnClicked()
                    }}
                    addActivityBtnClicked={() => {
                        addActivityBtnClicked()
                    }}
                    addContactBtnClicked={() => {
                        addContactBtnClicked()
                    }}
                >
                </CompanyPageModalMobile>}

        </div>
    )
}

CompanyPage.propTypes = {
    companyActions: PropTypes.object,
    company: PropTypes.object,
    history: PropTypes.object,
};

CompanyPage.defaultProps = {
    companyActions: {},
    company: {},
    history: {},
};

function mapStateToProps(state) {
    if (state.company) {
        return {
            company: state.company
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
        companyActions: bindActionCreators(CompanyActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
