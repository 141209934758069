import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from "../InfiniteScroll/InfiniteScrollComponent";
import {Oval} from "react-loader-spinner";
import { formatFullName } from '../../utils/helpers';
import './CompanyContacts.css';

const CompanyContactsMobile = (props) => {
    const { contactsActive,
        contactsArchive,
        selectedContactHeaderTab,
        handleNext,
        history,
        type,
        companyId
    } = props;

    contactsActive.elements = contactsActive?.activeTotal > 0 ? contactsActive.elements : [];
    
    const handleNextItems = (e) => {
        handleNext(selectedContactHeaderTab)
    }


    const renderContactList = (list) => {
        return list?.sort((a, b) => a.lastName ? a.lastName.localeCompare(b.lastName): a.name.localeCompare(b.name)).map((contact) => (
            <div className="companyContact" onClick={() => {
                history.push(`/contact/${contact?.id}?type=${type[0]}&companyId=${companyId}`);
            }}>
                {type === 'contacts' ?
                    <div className="companyContactCircle">{contact?.firstName?.[0]}{contact?.lastName?.[0]}</div> :
                    <div className="companyContactCircle">{`${contact?.name?.split(', ')?.[1]?.[0]}${contact?.name?.split(', ')?.[0]?.[0]}`}</div>}
                <div style={{margin: "auto 0"}}>
                    {type === 'contacts' ? <div className="companyContactName">{`${contact?.firstName} ${contact?.lastName}`}</div> :
                        <div className="companyContactName">{`${formatFullName(contact?.name)}`}</div>}
                    <div className="companyContactTitle">{contact?.title}</div>
                    {type !== 'contacts' ? <div className="companyContactRole">{contact?.role}</div> : '' }

                </div>
            </div>
        ))
    }
    return (
        <div className='contactsListContainer' id="CompanyContactsMobile">
            {((selectedContactHeaderTab.toLowerCase() === 'active' && contactsActive?.elements?.length > 0) || (selectedContactHeaderTab.toLowerCase() === 'archive' &&contactsArchive?.elements?.length > 0)) &&
                <InfiniteScroll
                scrollThreshold={'50px'}
                dataLength={selectedContactHeaderTab.toLowerCase() === 'active' ? contactsActive?.activeTotal :  contactsArchive?.inactiveTotal}
                hasMore={selectedContactHeaderTab.toLowerCase() === 'active' ? (contactsActive?.elements?.length < contactsActive?.activeTotal) :  (contactsArchive?.elements?.length < contactsArchive?.inactiveTotal)}
                loader={<h4 className="resultsMessage">Loading...</h4>}
                load={handleNext}
                endMessage={
                <p className="resultsMessage">
                <b>No More Results</b>
                </p>
            }
                >
            {selectedContactHeaderTab.toLowerCase() === 'active' ? renderContactList(contactsActive?.elements): renderContactList(contactsArchive?.elements)}
                </InfiniteScroll>}
            {(
                (selectedContactHeaderTab.toLowerCase() === 'active' && contactsActive?.elements?.length === 0 && contactsActive?.activeTotal !==0) ||
                (selectedContactHeaderTab.toLowerCase() === 'archive' && contactsArchive?.elements?.length === 0 && contactsActive?.inactiveTotal !==0)
            ) &&  <div className="loaderSection">
                <Oval
                    visible={true}
                    height="40"
                    width="40"
                    color="#000000"
                    secondaryColor={"gray"}
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>}
                </div>
    )
}

CompanyContactsMobile.propTypes = {
    contactsActive: PropTypes.shape({
        elements: PropTypes.array,
        activeTotal: PropTypes.number
    }),
    contactsArchive: PropTypes.shape({
        elements: PropTypes.array,
        inactiveTotal: PropTypes.number
    }),
    selectedContactHeaderTab: PropTypes.string,
    handleNext: PropTypes.func,
    history: PropTypes.object,
    type: PropTypes.string,
    companyId: PropTypes.string
};

CompanyContactsMobile.defaultProps = {
    contactsActive: {
        elements: [],
        activeTotal: 0
    },
    contactsArchive: {
        elements: [],
        inactiveTotal: 0
    },
    selectedContactHeaderTab: '',
    handleNext: () => {},
    history: {},
    type: '',
    companyId: ''
};

export default CompanyContactsMobile;
