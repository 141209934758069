import { BASE_URL, AuthHeaders } from "./config";
import axios from "axios";

export const lookup = async (data) => {
    const response = await axios.get(
        `${BASE_URL}/lookups/${data}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    if(data.includes('countries')) {
        let usData = response.data.filter(item => item.name.toLowerCase() === 'united states')[0];
        response.data = response.data.filter(item => item.name.toLowerCase() !== 'united states');
        response.data.unshift(usData);
    }

    return response.data;
};
