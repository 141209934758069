function pickInterpolatedVars(obj) {
    if (!obj) {
        return {};
    }

    return Object.keys(obj).reduce((acc, c) => {
        const match = /#{/.test(obj[c]);
        if (!match) {
            return {
                ...acc,
                [c]: obj[c]
            };
        }

        return acc;
    }, {});
}

export default pickInterpolatedVars;
