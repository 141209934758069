import initialState from '../initialState.json';
import {
    EDIT_CONTACT_DETAILS,
    EDIT_CONTACT_DETAILS_SUCCESS,
    REMOVE_CONTACT,
    GET_CONTACT_DETAILS_SUCCESS
} from "../actions/Contact.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_CONTACT_DETAILS_SUCCESS: {
            return {
                ...state,
                contactDetails: action.payload,
            }
        }

        case REMOVE_CONTACT: {
            return {
                ...state,
                contactDetails: null,
            }
        }

        case EDIT_CONTACT_DETAILS_SUCCESS: {
            return {
                ...state,
                contactDetails: action.payload,
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}