import initialState from '../initialState.json';
import {LOOKUP_SUCCESS} from "../actions/Lookup.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case LOOKUP_SUCCESS: {
            return {
                ...state,
                lookup: action.payload,
                
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}