import initialState from '../initialState.json';
import {
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_FEED,
    GET_COMPANY_FEED_SUCCESS,
    GET_COMPANY_DETAILS,
    CLEAR_COMPANY_DETAILS, GET_COMPANY_CONTACTS_SUCCESS
} from "../actions/Company.actions";
import {cloneDeep} from 'lodash';

export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_COMPANY_FEED: {
            return {
                ...state,
                isLoading: true,

            }
        }

        case GET_COMPANY_DETAILS: {
            return {
                ...state,
                companiesCount: 0,
                contactsCount: 0,
                companyContactsActive: {
                    elements: []
                },
                companyContactsArchive: {
                    elements: []
                },
                companyJllContactsActive: {
                    elements: []
                },
                companyJllContactsArchive: {
                    elements: []
                },
                contact: {
                }
            }
        }

        case CLEAR_COMPANY_DETAILS: {
            return {
                ...state,
                "companyFeed": [],
                "companyActivityFeed": [],
                "companyOpportunitiesFeed": [],
                totalCount: 0,
                activityCount:0,
                oppCount: 0,
                newsFeed: [],
                companyInformation: null
            }
        }

        case GET_COMPANY_FEED_SUCCESS: {
            let companyFeedTemp =  cloneDeep(state.companyFeed);
            let companyOpportunitiesFeedTemp =  state.companyOpportunitiesFeed;
            let companyActivitiesFeedTemp =  cloneDeep(state.companyActivityFeed);
            if(action?.payload?.activitiesOnly && !action.payload.refreshFeed) {
                companyActivitiesFeedTemp = companyActivitiesFeedTemp.concat(action.payload.newsFeed);
            } else if (action?.payload?.opportunitiesOnly) {
                companyOpportunitiesFeedTemp = companyOpportunitiesFeedTemp.concat(action.payload.newsFeed);
            } else if(action.payload.refreshFeed) {
                if(action?.payload?.activitiesOnly) {
                    companyActivitiesFeedTemp =  action.payload.newsFeed;
                } else {
                    companyFeedTemp = action.payload.newsFeed;
                }
            } else {
                companyFeedTemp = companyFeedTemp.concat(action.payload.newsFeed);
            }
            return {
                ...state,
                companyFeed: companyFeedTemp,
                companyActivityFeed: companyActivitiesFeedTemp,
                companyOpportunitiesFeed: companyOpportunitiesFeedTemp,
                isLoading: false,
                ...action.payload

            }
        }
        case GET_COMPANY_DETAILS_SUCCESS: {
            return {
                ...state,
                companyInformation: {
                    ...action.payload,
                },
            }
        }

        case GET_COMPANY_CONTACTS_SUCCESS: {
            let companyContactsActiveTemp = state.companyContactsActive;
            let companyContactsArchiveTemp = state.companyContactsArchive;
            let companyJllContactsActiveTemp = state.companyJllContactsActive;
            let companyJllContactsArchiveTemp = state.companyJllContactsArchive;
            if(action.payload?.type === 'contacts') {
                if(action.payload?.isActive) {
                    companyContactsActiveTemp.elements = action.payload.reset ? action.payload.elements : companyContactsActiveTemp.elements.concat(action.payload.elements);
                } else {
                    companyContactsArchiveTemp.elements = action.payload.reset ? action.payload.elements : companyContactsArchiveTemp.elements.concat(action.payload.elements);
                }
            } else {
                if(action.payload?.isActive) {
                    companyJllContactsActiveTemp.elements = action.payload.reset ? action.payload.data : companyJllContactsActiveTemp.elements.concat(action.payload.data);
                } else {
                    companyJllContactsArchiveTemp.elements = action.payload.reset ? action.payload.data : companyJllContactsArchiveTemp.elements.concat(action.payload.data);
                }
            }
            if(action.payload.type === 'contacts') {
                return {
                    ...state,
                    companyContactsActive: {
                        elements: companyContactsActiveTemp.elements,
                        totalCount: action.payload.totalCount,
                        activeTotal: action.payload.activeTotal,
                        inactiveTotal: action.payload.inactiveTotal,

                    },
                    companyContactsArchive: {
                        elements: companyContactsArchiveTemp.elements,
                        totalCount: action.payload.totalCount,
                        activeTotal: action.payload.activeTotal,
                        inactiveTotal: action.payload.inactiveTotal,
                    },
                }
            } else {
                return {
                    ...state,
                    companyJllContactsActive: {
                        elements: companyJllContactsActiveTemp.elements,
                        totalCount: action.payload.total,
                        activeTotal: action.payload.activeTotal,
                        inactiveTotal: action.payload.inactiveTotal,

                    },
                    companyJllContactsArchive: {
                        elements: companyJllContactsArchiveTemp.elements,
                        totalCount: action.payload.total,
                        activeTotal: action.payload.activeTotal,
                        inactiveTotal: action.payload.inactiveTotal,
                    },
                }
            }

        }
        default: {
            return {
                ...state
            }
        }
    }
}