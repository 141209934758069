import React from 'react';
import { SolIcon } from "@solstice/sol-react";
import moment from "moment";
import toDoListEmpty from '../../assets/toDoListEmpty.svg';
import './ToDoList.css';

const toDoList = (props) => {
  const { toDoList } = props;
  let toDoListPreview = toDoList;
  if (toDoList?.length > 2) {
    toDoListPreview = toDoList.slice(0, 2);
  }

  return (
    <div className='toDoList' id='toDoList'>
      {toDoListPreview?.length === 0 ?
        <div className='toDoListEmpty'>
          <img style={{ paddingBottom: '20px' }} src={toDoListEmpty} />
          <br />
          <text>Activities added to the To Do List will appear here</text>
        </div> :
        toDoListPreview?.map((activity, index) => {
          return (
            <div style={{display: 'flex'}} className={index === 1 && 'toDoListSecondItem'}>
              <div>
                <text style={{ color: '#01151D' }}>
                  {activity.regarding.displayName}
                </text>
                <text className='toDoListDate'>
                  {moment(activity.createdOn).format('ll')}
                </text>
                <br />
                {activity.subject}
              </div>
              <SolIcon slot="icon" icon="chevron_right" className='toDoListChevron' />
            </div>
          )
        })}
    </div>
  )
};

export default toDoList;