import {SolDialog, SolIconBubble} from '@solstice/sol-react';
import './ModalExit.css';
import PropTypes from 'prop-types';

export const ModalExitMobile = (props) => {
    const {SetExitModal, closeActivityModalBtnClicked, closeContactModalBtnClicked} = props;
    return (
        <div id='ModalExitMobile'>
            <SolDialog
                    hasBackdrop
                    className="exitModal"
            >
                <div slot="body" style={{display: 'grid', justifyContent: 'center', textAlign: 'center'}}>
                    <SolIconBubble icon="exclamation" size="40" style={{marginTop: '20px'}} />
                    <div style={{fontSize: '20px', color: '#01151C', marginTop: '10px'}}>Are you sure you want to exit?</div>
                    <div style={{color: '#667579', marginTop: '10px'}}>Your work will not be saved</div>
                    <div
                        className='buttonClass margin-top-20'
                        style={{ background: '#01151C', color: '#FFFFFF' }}
                        onClick={() => {
                            closeActivityModalBtnClicked();
                            closeContactModalBtnClicked();
                            SetExitModal(false);
                        }}
                    >
                        Exit Form
                    </div>
                    <div
                        className='buttonClass margin-top-12'
                        onClick={() => {
                            SetExitModal(false);
                        }}
                    >
                         Return to Form
                    </div>
                </div>
            </SolDialog>
        </div>
    )
}

ModalExitMobile.propTypes = {
    SetExitModal: PropTypes.func,
    closeActivityModalBtnClicked: PropTypes.func,
    closeContactModalBtnClicked: PropTypes.func,
};

ModalExitMobile.defaultProps = {
    SetExitModal: () => {},
    closeActivityModalBtnClicked: () => {},
    closeContactModalBtnClicked: () => {},
};

export default ModalExitMobile;