import initialState from '../initialState.json';
import {
    ADD_BLOG_ENTRY,
    GET_BLOG_ENTRIES_SUCCESS,
    ADD_BLOG_ENTRY_SUCCESS,
    UPDATE_BLOG_ENTRY_SUCCESS,
    GET_BLOG_OWNERS_SUCCESS, GET_BLOG_ENTRIES,
    GET_BLOG_REPORT, GET_BLOG_REPORT_SUCCESS
} from "../actions/Echelon.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_BLOG_REPORT: {
            let tempSearchParams = {
                ...state.searchParams,
                ...action.payload.searchData
            }
            return {
                ...state,
                searchParams: tempSearchParams
            }
        }

        case GET_BLOG_REPORT_SUCCESS: {
            let tempBlogEntries = state.blogEntries.allBlogs;
            if(action.payload.addedNewBlog || action.payload.newSearch){
                tempBlogEntries = action.payload.allBlogs;
            } else {
                tempBlogEntries = [
                    ...state.blogEntries.allBlogs,
                    ...action.payload.allBlogs
                ]
            }
            return {
                ...state,
                blogEntries: {
                    ...action.payload,
                    allBlogs: tempBlogEntries
                },

            }
        }

        case GET_BLOG_ENTRIES: {
            let tempSearchParams = {
                ...state.searchParams,
                ...action.payload.searchData
            }
            return {
                ...state,
                searchParams: tempSearchParams
            }
        }

        case GET_BLOG_ENTRIES_SUCCESS: {
            let tempBlogEntries = state.blogEntries.allBlogs;
            if(action.payload.addedNewBlog || action.payload.newSearch){
                tempBlogEntries = action.payload.allBlogs;
            } else {
                tempBlogEntries = [
                    ...state.blogEntries.allBlogs,
                    ...action.payload.allBlogs
                ]
            }
            return {
                ...state,
                blogEntries: {
                    ...action.payload,
                    allBlogs: tempBlogEntries
                },

            }
        }

        case ADD_BLOG_ENTRY_SUCCESS: {
            return {
                ...state,
                resetSearchFilters: false,
            }
        }

        case ADD_BLOG_ENTRY: {
            return {
                ...state,
                resetSearchFilters: true,
            }
        }

        case GET_BLOG_OWNERS_SUCCESS: {
            return {
                ...state,
                blogOwners: action.payload
            }
        }

        case UPDATE_BLOG_ENTRY_SUCCESS: {
            let tempBlogEntries = state.blogEntries.allBlogs;
            let updatedBlogIndex = tempBlogEntries.findIndex(blog => blog.blogEntryId === action.payload.blogEntryId);
            tempBlogEntries[updatedBlogIndex] = action.payload;
            return {
                ...state,
                blogEntries: {
                    ...state.blogEntries,
                    allBlogs: tempBlogEntries
                }
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}