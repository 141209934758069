import { BASE_URL, AuthHeaders, PublicApiUri, PublicApiKey, SpiderApiUri } from "./config";
import axios from "axios";
// import { orderBy, isEmpty } from 'lodash';
// import { getUser } from '../../utils/tokenManager';

export const searchCompanyAndContact = async (data) => {
    const response = await axios.get(
        `${BASE_URL}/${data.type}/search/?limit=30&narrowByTerm=&offset=${data.offset}&query=${data.searchText}&region=global&sortBy=-relevance&PageNo=${data.pageNo}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};
