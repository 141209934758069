import pickInterpolatedVars from "./pickInterpolatedVars";

const config = {
    client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirect_uri: process.env.REACT_APP_OKTA_REDIRECT_URI,
    responseType: process.env.REACT_APP_OKTA_RESPONSE_TYPE,
    logoutUrl: process.env.REACT_APP_OKTA_LOGOUT_URL,
    okta_base_url: process.env.REACT_APP_OKTA_BASE_URL
};

const oktaConfig = {
    ...config,
    ...pickInterpolatedVars((window).oktaConfig)
};

export default oktaConfig;
