import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SearchResultsSection.css';
import { SolIcon } from '@solstice/sol-react';
import CompanyResult from "../SearchResults/CompanyResult";
import InfiniteScroll from "../InfiniteScroll/InfiniteScrollComponent";
import ContactResult from "../SearchResults/ContactResult";
import { Oval } from "react-loader-spinner";
class SearchResultsSectionMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companyData: []
        };
        this.handleNext = this.handleNext.bind(this);
    }

    handleNext(e) {
        const { type, searchDetails, companiesCount, contactsCount } = this.props;
        let allowCall = {
            companies: searchDetails?.companiesResults?.length < companiesCount,
            contacts: searchDetails?.contactsResults?.length < contactsCount
        }
        if (allowCall[type]) {
            this.props.handleSearch(false, type, e);
        }
    }
    render() {

        let { handleSearchClicked, searchText, searchDetails, type, history, isLoading, contactsCount, companiesCount } = this.props;
        return (
            <div className='searchResultsContainer'>
                <div>
                    {((!searchDetails?.[`${type}Results`] || searchDetails?.[`${type}Results`]?.length === 0) && !isLoading) ? (
                        <div>
                            {(searchText?.length && handleSearchClicked)?
                                <div className='emptyResults-div'>
                                    <div style={{ textAlign: 'center' }}>
                                        <SolIcon icon="search_off"
                                            style={{ color: '#667579' }}
                                            size="24">
                                        </SolIcon>
                                    </div>
                                    <div className='noResultsFound-div'>No results found</div>
                                    <div className='couldnotFind-div'>
                                        We couldn't find any records with that name
                                    </div>
                                </div> :
                                <div className='emptyResults-div'>
                                    <div className='typeText-div'>To search for a company or contact, type a name in the field above</div>
                                </div>}

                        </div>) : <div>
                        {(searchDetails?.[`${type}Results`]?.length === 0 && isLoading) ?
                            <div className="loaderSection">
                                <Oval
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#000000"
                                    secondaryColor={"gray"}
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /></div> : (<div>
                                    <div className='resultsCount-div'>
                                        {type === 'companies' ? companiesCount : contactsCount} RESULTS
                                    </div>
                                    <div id='infiniteScroll' style={{ maxHeight: `${window.innerHeight - 300}px`, padding: '0 20px' }}>
                                        <InfiniteScroll
                                            scrollThreshold={'200px'}
                                            dataLength={type === 'companies' ? parseInt(companiesCount) : parseInt(contactsCount)}
                                            hasMore={type === 'companies' ? searchDetails?.companiesResults?.length < companiesCount : searchDetails?.contactsResults?.length < contactsCount}
                                            loader={<h4>Loading...</h4>}
                                            load={this.handleNext}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>No More Results</b>
                                                </p>
                                            }
                                        >
                                            {searchDetails?.[`${type}Results`]?.map(item => {
                                                return (type === 'companies' ? <CompanyResult history={history} company={item} /> : <ContactResult history={history} contact={item} />)
                                            })}
                                        </InfiniteScroll>
                                    </div>
                                </div>)}
                    </div>}
                </div>
            </div>
        );
    }


}

SearchResultsSectionMobile.propTypes = {
    handleSearchClicked: PropTypes.bool,
    searchText: PropTypes.string,
    searchDetails: PropTypes.shape({
        companiesResults: PropTypes.array,
        contactsResults: PropTypes.array
    }),
    type: PropTypes.string,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    contactsCount: PropTypes.number,
    companiesCount: PropTypes.number,
    handleSearch: PropTypes.func
};

SearchResultsSectionMobile.defaultProps = {
    handleSearchClicked: false,
    searchText: '',
    searchDetails: {
        companiesResults: [],
        contactsResults: []
    },
    type: '',
    history: {},
    isLoading: false,
    contactsCount: 0,
    companiesCount: 0,
    handleSearch: () => {}
};

export default SearchResultsSectionMobile;
