import aeroConfig from "../config/aeroConfig";

export const userProfileDropdownOptions = [
    {
        id: 1,
        path: '/prospecting/admin',
        label: 'Administration',
        value: 'administration'
    },
    {
        label: 'User Profile Admin',
        value: 'userProfileAdmin ',
    },
    {
        label: 'User Delegate',
        value: 'userDelegate'
    },
    {
        label: 'Email Log',
        value: 'emailLog',
        path: '/prospecting/email-log'
    },
    {
        label: 'Approvals Plus',
        value: 'approvalsPlus',
        path: '/prospecting/my-opportunities/approvalPlus'
    },
    {
        label: 'Logout',
        url:  aeroConfig.oktalogout || process.env.REACT_APP_OKTALOGOUT,
        value: 'Logout',
    }
]

export const userRecordsDropdownOptions = [
    {
        id: 1,
        label: 'Activities',
        value: 'Activities',
        path: '/prospecting/my-activities',
    },
    {
        label: 'Companies',
        value: 'Companies ',
        path: '/prospecting/my-companies',
    },
    {
        label: 'Opportunities',
        value: 'opportunities',
        path: '/prospecting/my-opportunities/enablement'
    },
    {
        label: 'Marketing Lists',
        value: 'Marketing Lists',
        path: '/prospecting/my-marketing-lists'
    },
    {
        label: 'Approvals',
        value: 'Approvals',
        path: '/prospecting/my-opportunities/approval'
    },
    {
        label: 'Market Users',
        value: 'Market Users',
        path:  '/prospecting/my-market-users'
    },
    {
        label: 'Prospect List',
        value: 'Prospect List',
        path:  '/prospecting/prospect-list/all'
    }
]

export const headerCreateDropdownOptions = [
    {
        id: 1,
        label: 'Company',
        value: 'Company',
    },
    {
        label: 'Contact',
        value: 'Contact ',
    },
    {
        label: 'Property',
        value: 'Property',
    },
    {
        label: 'Opportunity',
        value: 'Opportunity',
        path: '/prospecting/opportunity'
    },
    {
        label: 'Marketing list ',
        value: 'Marketing list ',
        path:  '/prospecting/my-marketing-lists?create=true'
    }
]

export const headerNavLinks = {
    'Home': '/',
    'Opportunity': '/prospecting/home/opportunities',
    'Financials': '/prospecting/home/financials',
    'Dashboard': '/prospecting/home/dashboards',
    'Reports': '/prospecting/home/reports',
    'Tools': '/prospecting/home/tools'
}