import {useRef, useCallback, useEffect} from "react";
import PropTypes from "prop-types";

const InfiniteScroll = ({
                                   load,
                                   hasMore,
                                   loader,
                                   children,
                                   endMessage,
                               }) => {
    const sentinelRef = useRef(null);
    const observerRef = useRef(null);

    const handleIntersect = useCallback(
        (
            entries,
            observer
        ) => {
            if (entries[0].isIntersecting && hasMore) {
                load();
            }
        },
        [load]
    );

    useEffect(() => {
        observerRef.current = new IntersectionObserver(handleIntersect, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        });

        if (sentinelRef.current) {
            observerRef.current.observe(sentinelRef.current);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [load]);

    useEffect(() => {
        if (observerRef.current && sentinelRef.current) {
            observerRef.current.disconnect();
            observerRef.current.observe(sentinelRef.current);
        }
    }, [hasMore]);

    return (
        <div>
            {children}
            <div ref={sentinelRef}>{hasMore && loader}</div>
            {!hasMore && endMessage}
        </div>
    );
};

InfiniteScroll.propTypes = {
    load: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    loader: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    endMessage: PropTypes.node.isRequired,
};


export  default InfiniteScroll;