import React, { useState, useEffect } from 'react';
import './BlogListContainer.css';
import InfiniteScroll from "../../InfiniteScroll/InfiniteScrollComponent";
import { useDispatch, useSelector } from "react-redux";
import { getBlogEntries } from "../../../store/actions/Echelon.actions";
import moment from 'moment';
import { SolButton, SolCheckbox, SolDialog, SolIcon, SolDialogHeader, SolDialogFooter } from "@solstice/sol-react";
import { getUser } from "../../../utils/tokenManager";
import addIcon from "../../../assets/addIcon.svg";
import { isEmpty } from "lodash";
import suneditor from 'suneditor'
import { MultiSelect } from 'react-multi-select-component';
import { lookup } from "../../../store/actions/Lookup.actions";
import { addBlogEntry, updateBlogEntry } from "../../../store/actions/Echelon.actions";
import 'suneditor/dist/css/suneditor.min.css'

const BlogListContainerDesktop = (props) => {

  const dispatch = useDispatch();
  const user = getUser();
  const [submarketSuggestions, setSubmarketSuggestions] = useState([]);
  const [submarketValue, setSubmarketValue] = useState([]);
  const [tim, setTim] = useState(false);
  const [jllOnly, setJllOnly] = useState(false);
  const [blogText, setBlogText] = useState('');
  const submarkets = useSelector(state => state.lookup.lookup);

  const blogEntries = useSelector(state => state.echelon.blogEntries);

  const handleNext = () => {
    dispatch(getBlogEntries({
      searchData: {
        TIM: null,
        JLLOnly: null,
        BlogText: null,
        CreatedBy: null,
        CreatedByName: null,
        StartDate: "",
        EndDate: "",
        PageSize: 50,
        PageNo: parseInt(blogEntries?.allBlogs?.length / 50) + 1
      },
      newSearch: false,

    }));
  }
  const { showAddBlog, setShowAddBlog, editBlog } = props;

  const isEditableUser = (entry) => {
    let userFirstName = (user.split('@')?.[0])?.split('.')?.[0];
    let userLastName = (user.split('@')?.[0])?.split('.')?.[0];
    return (entry.createdByName.includes(userFirstName) && entry.createdByName.includes(userLastName));
  }

  const getBlogEntryUserInitials = (entry) => {
    if (entry?.createdByName.includes(',')) {
      return `${entry?.createdByName?.split(',')?.[1]?.[1] || ''}${entry?.createdByName?.split(',')?.[0]?.[0] || ''}`;
    } else {
      return `${entry?.createdByName?.split(' ')?.[1]?.[0] || ''}${entry?.createdByName?.split(' ')?.[0]?.[0] || ''}`;

    }
  }

  useEffect(() => {
    if (showAddBlog) {
      // console.log(document.getElementsByClassName('sol-dropdown__list-container'));
      if (document.getElementById('blogEntryEditor')) {
        let blogEntryEditorElement = suneditor.create(document.getElementById('blogEntryEditor'), {
          fontSize: 14,
          width: "100%",
          stickyToolbar: 0,
          value: blogText || editBlog?.blogText,
          buttonList: [],
          tagsBlacklist: 'script|img|svg|a',
          pasteTagsBlacklist: 'script|img|svg|a',
          resizingBar: false,
        });
        blogEntryEditorElement.onChange = function (contents, core, e) {
          core.focus();
          setBlogText(contents);

        }
        blogEntryEditorElement.setDefaultStyle('font-family: Source Sans Pro; font-size: 16px; color: #667579');

      }

    }
  }, [showAddBlog])



  const resetBlogEntry = () => {
    const { setEditBlog } = props;
    setSubmarketValue([]);
    setBlogText('');
    setTim(false);
    setJllOnly(false);
    setEditBlog(null);
  }


  useEffect(() => {
    if (editBlog) {
      setSubmarketValue(editBlog.subMarkets.map(item => {
        return {
          label: item.displayName,
          value: item.displayName,
          id: item.id
        }

      }));
      setTim(editBlog.tim);
      setJllOnly(editBlog.jllOnly);
      setBlogText(editBlog.blogText);
    }
  }, [editBlog]);

  useEffect(() => {
    let tempSubmarkets = [];
    tempSubmarkets = submarkets.map(item => {
      return { label: item.value, ...item }
    });
    setSubmarketSuggestions(tempSubmarkets);

  }, [submarkets]);

  useEffect(() => {
    dispatch(lookup('GetBlogSubMarkets'));
  }, []);



  const handleAddBlogEntry = () => {
    let submarketsIds = submarketValue.map(item => parseInt(item.id));
    if (editBlog) {
      dispatch(updateBlogEntry({
        blogData: {
          blogEntryId: editBlog.blogEntryId,
          blogText: blogText,
          jllOnly: jllOnly,
          tim: tim,
          createdBy: editBlog.createdBy ? editBlog.createdBy : null,
          createdByName: editBlog.createdBy ? null : editBlog.createdByName,
          submarketIds: submarketsIds,
        },
        addedNewBlog: true
      }))
    } else {
      dispatch(addBlogEntry({
        newBlogData: {
          "blogText": blogText,
          "jllOnly": jllOnly,
          "tim": tim,
          "submarketIds": submarketsIds,
        }
      }));
    }

    resetBlogEntry();
    setShowAddBlog(false);

  }

  return (
    <div className='blogListContainerDesktop'>
      <div className='addNewBtnPrimary'>
        <div className='addNewBtn' onClick={() => { setShowAddBlog(true); }}>
          <img
            alt=""
            className='addNewBlogIcon'
            src={addIcon}
          />
          <div
            className={'addNewText'}
          >
            Add New
          </div>
        </div>
      </div>
      <div className={'scrollContainer'}>
        {blogEntries?.totalBlogsCount > 0 ? <InfiniteScroll
          scrollThreshold={'200px'}
          dataLength={blogEntries?.totalBlogsCount || 0}
          hasMore={blogEntries?.totalBlogsCount > blogEntries?.allBlogs?.length}
          loader={<h4 className="resultsMessage">Loading...</h4>}
          load={handleNext}
          endMessage={
            <p className="resultsMessage" />
          }
        >
          {blogEntries?.allBlogs?.filter(item => item.blogEntryId !== editBlog?.blogEntryId).map((entry, index) => {
            return <div id={entry?.blogEntryId}>
              <div className={'blogCardMainContainer'}>
                <div style={{ width: '6%', margin: '0 10px 10px 0' }}>
                  <div className="companyContactCircle">{getBlogEntryUserInitials(entry)}</div>
                </div>
                <div style={{ width: '92%' }}>
                  <div className={'blogCardHeader'}>
                    <div className='blogPillsContainer'>
                      {entry.jllOnly && <div className='jllOnlyPill'>
                        JLL Only
                      </div>}
                      {entry.tim && <div className='timPill'>
                        TIM
                      </div>}
                    </div>
                    {entry?.subMarkets?.map((submarket, index) => {
                      return <div className='subMarketPill'> {submarket.displayName}</div>
                    })}
                  </div>
                  <div className={'blogCardBody'}
                    dangerouslySetInnerHTML={{ __html: entry?.blogText.replace(/<a .*?>/g, "") }} />
                  <div className={'blogCardFooter'}>
                    <div className={'blogCardFooterText'}>
                      {`Posted by `}<b>{entry?.createdByName}</b> on {moment(entry?.createdOn).format('MM/DD/YYYY')}
                    </div>
                  </div>
                </div>
                <div className='editIcon'>
                  {isEditableUser(entry) && <div onClick={() => {
                    props.handleEditBlog(entry);
                  }}>
                    <SolIcon icon="edit" size="19" />
                  </div>}
                </div>
              </div>
            </div>

          })}
        </InfiniteScroll> : <div>
          <div className='emptyResults-div'>
            <div style={{ textAlign: 'center' }}>
              <SolIcon icon="search_off"
                style={{ color: '#667579' }}
                size="24">
              </SolIcon>
            </div>
            <div className='noResultsFound-div'>No Blogs found</div>
            <div className='couldnotFind-div'>
              We couldn't find any results with that search criteria
            </div>
          </div>
        </div>}
      </div>
      {showAddBlog && <SolDialog
          hasBackdrop={true}
          width={800}
          className="activityModal"
          floatingPosition="center"
          onSol-close={() => {
            {
              setShowAddBlog(false);
              resetBlogEntry();

            }
          }}
      >
        <SolDialogHeader
            slot="header"
            label={"Add New Blog Entry"}
        />

        <div slot={'body'}>
          <div className={'blogEntryOutline'}>
            <div className='blogEntryContainer'>
              <div className='blogEntryHeaderContainer'>
                <div> <label className='frmLabel'>Submarket</label> </div>
                <MultiSelect
                    options={submarketSuggestions}
                    value={submarketValue}
                    hasSelectAll={false}
                    onChange={e => {
                      setSubmarketValue(e);
                    }}
                    labelledBy="Select"
                />
              </div>
              <div className='blogCheckboxPanel'>
                <div>
                  <SolCheckbox
                      checked={jllOnly}
                      onSol-checked={e => setJllOnly(!jllOnly)}
                      className="chkBox"></SolCheckbox><span className='checkBoxLabel'>JLL only</span>
                </div>
                <div>
                  <SolCheckbox
                      checked={tim}
                      name="TIM"
                      onSol-checked={e => setTim(!tim)}
                      className="chkBox"></SolCheckbox>
                  <span className='checkBoxLabel'>TIM</span>
                </div>
              </div>
            </div>
            <textarea
                value={blogText}
                id="blogEntryEditor"
                placeholder='Enter blog text...'
                rows="12"
                className="echelonBlogTextArea"
            ></textarea>
          </div>

        </div>
        <SolDialogFooter slot="footer">
          <div className={'blogEntryFooter'}>
            <div className={'footerButton'}>
              <SolButton
                  label={'Cancel'}
                  variant={'secondary'}
                  onSol-click={() => {
                    resetBlogEntry();
                    setShowAddBlog(false);
                  }}
                  size={'medium'}
              >
                Cancel
              </SolButton>

            </div>
            <div>
              <SolButton
                  label={editBlog ? 'Save' : 'Add Blog'}
                  disabled={submarketValue.length <= 0 || blogText.length <=0 || blogText === '<p><br></p>'}
                  onSol-click={() => handleAddBlogEntry()}
                  size={'medium'}
                  appendIcon={'chat_add_on'}
              >
                {editBlog ? 'Save' : 'Add Blog'}
              </SolButton>
            </div>
          </div>

        </SolDialogFooter>
      </SolDialog>}
    </div>
  )
}

export default BlogListContainerDesktop;