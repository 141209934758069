import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import * as moment from "moment";

export const JLL_SESSION_NAME = "temp";
export const OKTA_TOKEN_STORAGE = "okta-token-storage";
export const EXTERNAL_USER_ID = "externaluser";

export const isTokenExpired = () => {

    const accessToken = localStorage.getItem(OKTA_TOKEN_STORAGE);

    if (!accessToken) {
        return true;
    }
    dayjs.extend(dayjsPluginUTC);
    const oktaToken = JSON.parse(accessToken || "");
    if (!oktaToken.accessToken) {
        return true;
    }

    let expiresAt = moment.unix(parseInt(oktaToken.accessToken.expiresAt, 10));
    //expiresAt = moment().subtract(7,'d');

    const now = moment();
    const isTokenExpired = !now.isBefore(expiresAt);

    console.log("User ", getUser());
    console.log("User ExpiresAt", oktaToken.accessToken.expiresAt);
    console.log("When Will User Token Expire???", expiresAt.format('MM/DD/YYYY hh:mm:ss'));
    console.log("User Token Will Expire In ", expiresAt.diff(now, 'minutes'), ' Minutes');

    return isTokenExpired;
};

export const getAccessToken = () => {
    const accessToken = localStorage.getItem(OKTA_TOKEN_STORAGE);
    if (!accessToken) {
        return null;
    }

    const oktaToken = JSON.parse(accessToken || "");
    return oktaToken.accessToken && oktaToken.accessToken.accessToken;
};

export const getUser = () => {
    try {
        const oktaSession = localStorage.getItem(OKTA_TOKEN_STORAGE);
        const token = JSON.parse(oktaSession || "");
        //Get UPN from Access Token for One JLL
        return token.accessToken.claims.sub;
        //return idToken.idToken.claims.email;
    } catch {
        return undefined;
    }
};

export const deleteLocalStorageValue = key => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error(error);
    }
};

export const setLocalStorageValue = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error(error);
    }
};


export const isTokenTimeElapsed = (idleTime) => {
    let currentTime= moment();
    return currentTime.diff(idleTime) > 3000000
};