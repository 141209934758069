import React from 'react';
import PropTypes from 'prop-types';
import './AeroHomePage.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActivityActions from "../../store/actions/Activity.actions";
import * as LeaseActions from "../../store/actions/Lease.actions";
import GlobalHeader from "../../components/Aero/GlobalHeader/GlobalHeader";
import HomePageComponentDesktop from "../../components/HomePageComponent/HomePageComponentDesktop"
import ToDoList from "../../components/ToDoList/ToDoList";
import LeaseExpirations from "../../components/LeaseExpirations/LeaseExpirations";

const AeroHomePage = (props) => {
  const { history, activityActions, leaseActions, activity, lease } = props;
  React.useEffect(() => {
    activityActions.sortMyActivities({ direction: '-', limit: 100, sort: 'dueDate' });
    leaseActions.getExpiringLeases({ limit: 9, offset: 0 });
  }, []);

  return (
    <div>
      <GlobalHeader history={history} />
      <div className="col-8">
      </div>
      <div>
        <div className="col-4">
          <HomePageComponentDesktop header="Lease expirations" data={lease?.lease?.expiringLeases}>
            <LeaseExpirations leaseExpirations={lease?.lease?.expiringLeases} />
          </HomePageComponentDesktop>
        </div>
        <div className="col-4">
          <HomePageComponentDesktop header="To do list" data={activity?.toDoList?.activities}>
            <ToDoList toDoList={activity?.toDoList?.activities}/>
          </HomePageComponentDesktop>
        </div>
      </div>
    </div>
  )
}

AeroHomePage.propTypes = {
  history: PropTypes.object,
  searchActions: PropTypes.object,
  searchDetails: PropTypes.object,
};

AeroHomePage.defaultProps = {
  history: {},
  searchActions: {},
  searchDetails: {},
};

function mapStateToProps(state) {
  return {
    searchDetails: state.search,
    activity: state.activity,
    lease: state.lease,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(ActivityActions, dispatch),
    leaseActions: bindActionCreators(LeaseActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AeroHomePage);
