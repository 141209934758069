import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import trophy from '../../assets/trophy.svg';
import './CompanyDetailsHeader.css';

const CompanyDetailsHeader = (props) => {
    const { company } = props;

    const monthsFromRO = moment().diff(moment(company?.recentOpportunities?.[0]?.meetingDate).format('LLL'), 'months') || undefined;
    const daysFromRO = company?.recentOpportunities?.[0] ?
        moment().diff(moment(company?.recentOpportunities?.[0]?.meetingDate)
        .format('LLL'), 'days') : undefined;
    const daysFromSA = company?.lastSubstantialActivities?.[0] ?
        moment().diff(moment(company?.lastSubstantialActivities?.[0]?.startDate || company?.lastSubstantialActivities?.[0]?.dueDate)
        .format('LLL'), 'days') : undefined;

    return (
        <div className='CompanyDetailsHeader'>
            <div className='pills'>
                {company?.isClient && <div className='clientPill'>
                    <sol-icon icon='person' size={24} style={{ color: '#840E2C', marginTop: '3px' }} />
                    Client
                </div>}
                {daysFromSA <= 60 && <div className='substantialActivityPill'>
                    <sol-icon icon='vital_signs' size={24} style={{ color: '#0C7BA1', marginTop: '3px' }} />
                    {daysFromSA < 0 && 'upcoming'}
                    {daysFromSA >= 0 && daysFromSA <= 60 && `${daysFromSA}d ago`}
                </div>}
                {daysFromRO <= 180 && <div className='recentOpportunityPill'>
                    <img
                        className='trophy'
                        src={trophy}
                        alt="trophy"
                    />
                    {daysFromRO < 0 && 'upcoming'}
                    {daysFromRO >= 0 && daysFromRO <= 60 && `${daysFromRO}d ago`}
                    {daysFromRO > 60 && daysFromRO <= 180 && `${monthsFromRO}m ago`}
                </div>}
            </div>
            <div className='companyDetailsHeader'>
                {company?.companyName}
            </div>
            <div className='companyDetailsText1'>
                {company?.country && <sol-icon icon='location_on' size={16} style={{ color: '#667579' }} />}
                {company?.country?.displayName}
            </div>
            <div className='companyDetailsText2'>
                {company?.addressLine1 && `${company?.addressLine1}`}{company?.addressLine2 && `, ${company?.addressLine2}`}{company?.city && `, ${company?.city}`}{company?.state && `, ${company?.state}`}{company?.zipCode && `, ${company?.zipCode.split('-')[0]}`}
            </div>
        </div>
    )
}

CompanyDetailsHeader.propTypes = {
    company: PropTypes.shape({
        isClient: PropTypes.bool,
        recentOpportunities: PropTypes.arrayOf(
            PropTypes.shape({
                meetingDate: PropTypes.instanceOf(Date),
            })
        ),
        lastSubstantialActivities: PropTypes.arrayOf(
            PropTypes.shape({
                startDate: PropTypes.instanceOf(Date),
                dueDate: PropTypes.instanceOf(Date),
            })
        ),
        companyName: PropTypes.string,
        country: PropTypes.shape({
            displayName: PropTypes.string,
        }),
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
    }),
};

CompanyDetailsHeader.defaultProps = {
    company: {
        isClient: false,
        recentOpportunities: [],
        lastSubstantialActivities: [],
        companyName: '',
        country: null,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
    },
};

export default CompanyDetailsHeader;
