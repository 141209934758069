import initialState from '../initialState.json';
import {ADD_EDIT_ACTIVITY_SUCCESS, GET_ACTIVITY_BY_ID_SUCCESS, SORT_MY_ACTIVITIES_SUCCESS} from "../actions/Activity.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_ACTIVITY_BY_ID_SUCCESS: {
            return {
                ...state,
                activity: {
                    ...action.payload
                },
            }
        }
        case ADD_EDIT_ACTIVITY_SUCCESS: {
            return {
                ...state,
                activity: {
                    ...action.payload
                },
            }
        }
        case SORT_MY_ACTIVITIES_SUCCESS: {
          return {
            ...state,
            toDoList: {
              ...action.payload
            },
          }
        }
        default: {
            return {
                ...state
            }
        }
    }
}