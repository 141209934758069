import React from 'react';
import PropTypes from 'prop-types';
import jllLogo from '../../assets/JllLogo.svg';
import {deleteLocalStorageValue, getUser} from "../../utils/tokenManager";
import aeroConfig from "../../config/aeroConfig";
import './Header.css';
import {SolIcon} from "@solstice/sol-react";

const HeaderMobile = (props) => {


    const user = getUser();
    const oktalogout = aeroConfig.oktalogout || process.env.REACT_APP_OKTALOGOUT;
    const { showJllLogo } = props;
    const [showLogout, SetShowLogout] = React.useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get('type');
    const companyId = searchParams.get('companyId');
    const targetPage = searchParams.get('target');

    React.useEffect(() => {
        window.addEventListener('click',(e) => {
            if(e?.target?.id?.includes('user') || e?.target?.parentElement?.id.includes('user') || e?.target?.id.includes('logoutContainer')) {
                SetShowLogout(!showLogout);
            } else {
                SetShowLogout(false);
            }
        });
    }, [showLogout]);

    return (
    <div className='MainHeader' id='MainHeader'>
        {showJllLogo ? (<img
            className='jllLogo'
            src={jllLogo}
            alt="jllLogo"
        />) : (
            <div className='noJllLogo' onClick={() => {
                if(props.clearCompanyDetails) {
                    props.clearCompanyDetails();
                }
                if(window.location.pathname?.includes('contact') && type?.includes('j')) {
                    props.history.push(`/company/${companyId}?tab=jllcontacts`);
                } else if(window.location.pathname?.includes('contact') && !targetPage?.includes('search') ) {
                    props.history.push(`/company/${companyId}?tab=contacts`);
                } else {
                    if(targetPage?.includes('search')) {
                        props.history.push(`/?tabType=contacts`);
                    } else {
                        props.history.push(`/`);

                    }

                }

                }}>
            <SolIcon icon='arrow_back' size={20} style={{ color: '#0C7BA1' }}/>
                {window.location.pathname?.includes('contact') && type?.includes('j') ? 'Back to JLL contacts': window.location.pathname?.includes('contact') && !targetPage?.includes('search') ? 'Back to contacts': 'Back to search result'}
            </div>
        )}
        <div
            className='userSection'
            id='userSection'
            role="button"
           >
            <div id="userCircle" style={{display: "flex"}}>
            <div className='userCircle'>
                {user?.[0]}
                {user?.split('.')[1][0]}
            </div>
            <div
                style={{ alignSelf: 'center',  marginLeft: '5px' }}
                className='sol-icon-keyboard_arrow_down'
            />
            </div>
            <a
                id={'logoutContainer'}
                href={oktalogout}
                onClick={() => {
                    deleteLocalStorageValue('okta-token-storage');
                }}
                style={{ display: !showLogout && 'none' }}
            >
                <div className='LogoutTooltip'>
                    Log out
                    <div className='sol-icon-logout'/>
                    <div className="LogoutTooltipArrow"/>
                </div>
            </a>
        </div>
    </div>
    )
}

HeaderMobile.propTypes = {
    showJllLogo: PropTypes.bool,
    clearCompanyDetails: PropTypes.func,
    history: PropTypes.object,
};

HeaderMobile.defaultProps = {
    showJllLogo: true,
    clearCompanyDetails: () => {},
    history: {},
};

export default HeaderMobile;