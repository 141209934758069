/* eslint-disable */
import { getUser } from "./tokenManager";

export const setGA4Data = (eventName, data) => {
    gtag('event', eventName, {
        'user_email': data.user_email ? data.user_email : getUser() ? getUser() : " ",
        'searchedEntity': data.searchedEntity ? data.searchedEntity : " ",
        'searchedText': data.searchedText ? data.searchedText : " ",
        'companyID': data.companyID ? data.companyID : " "
    });
}