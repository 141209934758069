import React from 'react';
import PropTypes from 'prop-types';
import './SearchResults.css';

const ContactResult = (props) => {
    const { contact, index, history } = props;
    return (
        <div className='Result' id={index} onClick={() => {
            history.push(`/contact/${contact.id}/?type=c&companyId=${contact.companyId}&target=search`)}}>
            <div className='contactResultCircle'>
                {contact?.firstName?.[0]}
                {contact?.lastName?.[0]}
            </div>
            <div>
                <div className='contactResultText'>
                    {contact?.fullName}
                </div>
                <div className='contactResultText' style={{ color: '#667579' }}>
                    {contact?.company}{contact?.title && ` - ${contact?.title}`}
                </div>
            </div>
        </div>
    )
}

ContactResult.propTypes = {
    contact: PropTypes.shape({
        id: PropTypes.string,
        companyId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        fullName: PropTypes.string,
        company: PropTypes.string,
        title: PropTypes.string,
    }),
    index: PropTypes.number,
    history: PropTypes.object,
};

ContactResult.defaultProps = {
    contact: {
        id: '',
        companyId: '',
        firstName: '',
        lastName: '',
        fullName: '',
        company: '',
        title: '',
    },
    index: 0,
    history: {},
};

export default ContactResult;