import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CompanyFooter.css';
import { SolIcon } from "@solstice/sol-react";
import addIcon from '../../assets/addIcon.svg';

class CompanyFooterMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let { onTabSelectedChanged, tabSelected, addBtnClicked, showAddDialog } = this.props;
        return (
            <div className='companyFooter-main-div width-100pc'>
                <img onClick={() => { addBtnClicked() }}
                className='addIcon-img'
                    style={{ visibility: !showAddDialog ? 'visible' : 'hidden' }}
                    src={addIcon}></img>
                <div className='footeritems-div width-100pc'>
                    <div className='img-text-div' onClick={() => { onTabSelectedChanged('Feed') }}>
                        <div>
                            <SolIcon style={{ color: tabSelected === 'Feed' ? '#0C7BA1' : '#B6C0C2' }}
                                icon="breaking_news_alt_1" size="24">
                            </SolIcon>
                        </div>
                        <div className='footerText-div'
                            style={{ color: tabSelected === 'Feed' ? '#01151C' : '#667579' }}>
                            Feed
                        </div>
                    </div>
                    <div className='img-text-div' onClick={() => { onTabSelectedChanged('About Company') }}>
                        <div>
                            <SolIcon style={{ color: tabSelected === 'About Company' ? '#0C7BA1' : '#B6C0C2' }} icon="home_work" size="24">
                            </SolIcon>
                        </div>
                        <div className='footerText-div'
                            style={{ color: tabSelected === 'About Company' ? '#01151C' : '#667579' }}>
                            About Company
                        </div>
                    </div>
                    <div className='img-text-div' onClick={() => { onTabSelectedChanged('Contacts') }}>
                        <div>
                            <SolIcon style={{ color: tabSelected === 'Contacts' ? '#0C7BA1' : '#B6C0C2' }} icon="contacts" size="24">
                            </SolIcon>
                        </div>
                        <div className='footerText-div'
                            style={{ color: tabSelected === 'Contacts' ? '#01151C' : '#667579' }}>
                            Contacts
                        </div>
                    </div>
                    <div className='img-text-div' onClick={() => { onTabSelectedChanged('JLL Contacts' ) }}>
                        <div>
                            <SolIcon style={{ color: tabSelected === 'JLL Contacts' ? '#0C7BA1' : '#B6C0C2' }} icon="supervisor_account" size="24"></SolIcon>
                        </div>
                        <div className='footerText-div'
                            style={{ color: tabSelected === 'JLL Contacts' ? '#01151C' : '#667579' }}>
                            JLL Contacts
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CompanyFooterMobile.propTypes = {
    onTabSelectedChanged: PropTypes.func,
    tabSelected: PropTypes.string,
    addBtnClicked: PropTypes.func,
    showAddDialog: PropTypes.bool,
};

CompanyFooterMobile.defaultProps = {
    onTabSelectedChanged: () => {},
    tabSelected: '',
    addBtnClicked: () => {},
    showAddDialog: false,
};

export default CompanyFooterMobile;