import api from "../../api/index";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    GET_USER_PROFILE,
    GET_USERS,
    getUserProfileSuccess,
    getUsersSuccess,
} from "../actions/Users.actions";


export function* getUsersSaga(action) {
    try {
        const response = yield call(
            api.usersApi.fetchUsers,
            action.payload
        );
        yield put(getUsersSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* getUserProfileSaga(action) {
    try {
        const response = yield call(
            api.usersApi.fetchUserProfile,
            action.payload
        );
        yield put(getUserProfileSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchGetProfile() {
    yield takeEvery(GET_USER_PROFILE, getUserProfileSaga);
}
export function* watchGetUsers() {
    yield takeEvery(GET_USERS, getUsersSaga);
}

export default function* usersSagas() {
    yield all([
        watchGetUsers(),
        watchGetProfile()
    ]);
}