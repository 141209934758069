import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FeedCard.css';

import {SolIcon, SolIconBubble, SolBadge} from "@solstice/sol-react";

const FeedCard = (props) => {

    const {
        headerIcon,
        header,
        subHeader,
        feedDescription,
        content,
        headerIconName,
        headerIconVariant,
        isPrivate,
        headerDescription,
        isEditable,
        handleEditIconClick,
        editActivityBtnClicked,
        closeActivityModalBtnClicked,
        type,
    } = props;

    const [showDescriptionToggle, setShowDescriptionToggle] = useState(false);
    const [hideFeedDescription,  setHideFeedDescription] = useState(true);
    const divRef = useRef();

    useEffect(() => {
        setShowDescriptionToggle(divRef.current?.scrollHeight > 83);
    }, [divRef]);

    return (<>
        <div className="feedDivider" />
        <div className='feedCardContainer'>
            <div className='feedHeaderContainer'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='feedHeaderIcon'>
                    {headerIcon
                        ? headerIcon
                        : <SolIconBubble variant={headerIconVariant || 'default'} icon={headerIconName} size="36"/>
                    }
                </div>
                <div>
                    <div className='feedCardHeader'>
                        {header}
                        {isPrivate && <span className='headerPrivateIcon'>
                            <SolIcon icon='lock' size="20"/>
                        </span>}
                    </div>
                    {subHeader && <div className='feedCardSubHeader'>
                        {subHeader}
                    </div>}
                </div>
                </div>
                {isEditable && <div
                    role="button"
                    onClick={() => {
                        handleEditIconClick();
                    }}
                    className={'editIconContainer'}>{isEditable && <SolIcon icon="edit" size="24" />}
                </div>}

            </div>
            {headerDescription && <div className={"headerDescriptionContainer"}>
                {headerDescription}
            </div>}
            {content?.map((item, id) => {
                return item?.label && (
                    <div id={id} className='detailsContainer' style={{borderBottom: item.noBorder && 'none'}}>
                    <div className='detailsLabel'>
                        {item?.label}
                    </div>
                    <div className='detailsValue'>
                        {item.contentIcon && <span>
                            <SolIcon icon={item.contentIcon} size="20" />
                        </span>}
                        {item.pillText && <span> <SolBadge label={item.pillText || 'Active'} /> </span>}
                        <span className={"detailsValueText"}>{item.value}</span>
                    </div>
                </div>)
            })}
            <div>
                {feedDescription && 
                    <div className="feedDescriptionContainer" ref={divRef} style={{display: showDescriptionToggle && hideFeedDescription && '-webkit-box'}}>
                        {feedDescription}
                    </div>
                }
                {showDescriptionToggle &&
                    <div className='hideFeedDescription' onClick={() => {setHideFeedDescription(!hideFeedDescription)}}>
                        Full description
                        {hideFeedDescription ?
                            <SolIcon className='keyboard_arrow_down-img'
                                icon="keyboard_arrow_down" size="20" >
                            </SolIcon>
                            :
                            <SolIcon className='keyboard_arrow_down-img'
                                icon="keyboard_arrow_up" size="20" >
                            </SolIcon>
                        }
                    </div>
                }
            </div>
        </div>
    </>)
}

FeedCard.propTypes = {
    headerIcon: PropTypes.element,
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    feedDescription: PropTypes.string,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            contentIcon: PropTypes.string,
            pillText: PropTypes.string,
            value: PropTypes.string.isRequired,
            noBorder: PropTypes.bool,
        })
    ),
    headerIconName: PropTypes.string,
    headerIconVariant: PropTypes.string,
    isPrivate: PropTypes.bool,
    headerDescription: PropTypes.string,
    isEditable: PropTypes.bool,
    handleEditIconClick: PropTypes.func,
    editActivityBtnClicked: PropTypes.func,
    closeActivityModalBtnClicked: PropTypes.func,
    type: PropTypes.string,
};

FeedCard.defaultProps = {
    subHeader: '',
    feedDescription: '',
    content: [],
    headerIconName: '',
    headerIconVariant: '',
    isPrivate: false,
    headerDescription: '',
    isEditable: false,
    handleEditIconClick: () => {},
    editActivityBtnClicked: () => {},
    closeActivityModalBtnClicked: () => {},
    type: '',
};

export default FeedCard;