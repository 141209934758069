export const ADD_BLOG_ENTRY = "ADD_BLOG_ENTRY";
export const ADD_BLOG_ENTRY_SUCCESS = "ADD_BLOG_ENTRY_SUCCESS";
export const UPDATE_BLOG_ENTRY = "UPDATE_BLOG_ENTRY";
export const UPDATE_BLOG_ENTRY_SUCCESS = "UPDATE_BLOG_ENTRY_SUCCESS";
export const GET_BLOG_ENTRIES = "GET_BLOG_ENTRIES";
export const GET_BLOG_ENTRIES_SUCCESS = "GET_BLOG_ENTRIES_SUCCESS";
export const GET_BLOG_OWNERS = "GET_BLOG_OWNERS";
export const GET_BLOG_OWNERS_SUCCESS = "GET_BLOG_OWNERS_SUCCESS";
export const GET_BLOG_REPORT = "GET_BLOG_REPORT";
export const GET_BLOG_REPORT_SUCCESS = "GET_BLOG_REPORT_SUCCESS";

export function addBlogEntry(data) {
    return {
        payload: data,
        type: ADD_BLOG_ENTRY,
    };
}

export function addBlogEntrySuccess(data) {
    return {
        payload: data,
        type: ADD_BLOG_ENTRY_SUCCESS,
    };
}

export function updateBlogEntry(data) {
    return {
        payload: data,
        type: UPDATE_BLOG_ENTRY,
    };
}

export function updateBlogEntrySuccess(data) {
    return {
        payload: data,
        type: UPDATE_BLOG_ENTRY_SUCCESS,
    };
}

export function getBlogEntries(data) {
    return {
        payload: data,
        type: GET_BLOG_ENTRIES,
    };
}

export function getBlogEntriesSuccess(data) {
    return {
        payload: data,
        type: GET_BLOG_ENTRIES_SUCCESS,
    };
}

export function getBlogReport(data) {
    return {
        payload: data,
        type: GET_BLOG_REPORT,
    };
}

export function getBlogReportSuccess(data) {
    return {
        payload: data,
        type: GET_BLOG_REPORT_SUCCESS,
    };
}

export function getActivityById(data) {
    return {
        payload: data,
        type: GET_BLOG_ENTRIES,
    };
}

export function getActivityByIdSuccess(data) {
    return {
        payload: data,
        type: GET_BLOG_ENTRIES_SUCCESS,
    };
}

export function getBlogOwners(data) {
    return {
        payload: data,
        type: GET_BLOG_OWNERS,
    };
}

export function getBlogOwnersSuccess(data) {
    return {
        payload: data,
        type: GET_BLOG_OWNERS_SUCCESS,
    };
}