import React, { Component } from 'react';
import './ErrorContainer.css';
import jllLogo from '../../assets/JllLogo.svg';
import { SolIcon } from '@solstice/sol-react';
import HeaderMobile from '../Header/HeaderMobile'
import { setGA4Data } from "../../utils/googleAnalytics";

class SearchContainerMobile extends Component {

    componentDidMount() {
        setGA4Data('WeFoundAnErrorPage', {});
    }

    render() {
        return (
            <div className='error-main-div'>
                <div className='header-main-div'>
                    <div className='header-div'>
                        <HeaderMobile showJllLogo={true}></HeaderMobile>
                    </div>
                </div>
                <div className='main-error-div'>
                    <div>
                        <SolIcon
                            style={{
                                color: '#667579'
                            }}
                            icon="error"
                            size="32">
                        </SolIcon>
                    </div>
                    <p className='main-error'>
                        We found an error
                    </p>
                    <p className='main-error'>
                        Please refresh your page
                    </p>
                    <p style={{ color: '#667579' }}>If error persists, please email</p>
                    <a
                        className='hyperlinkColor'
                        href="mailto:BrokerTools@jll.com"
                    >
                        <u>BrokerTools@jll.com</u>
                    </a>
                </div>
            </div>
        );
    }


}

export default SearchContainerMobile;