import initialState from '../initialState.json';
import {GET_EXPIRING_LEASES_SUCCESS} from "../actions/Lease.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_EXPIRING_LEASES_SUCCESS: {
            return {
                ...state,
                lease: {
                    ...action.payload
                },
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}