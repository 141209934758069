import React from "react";
import PropTypes from 'prop-types';
import './ToggleSwitch.css';

export const ToggleSwitch =(props) => {
    return (
        <>
            <label className='switch'>
                <input type="checkbox" checked={props.checked} onChange={props.onToggleChange}/>
                <span className='slider round'></span>
            </label>
            <span className='toggle-message'>{props.title}</span>
        </>
    )
}

const propTypes = {
    title: PropTypes.string,
    onToggleChange: PropTypes.func,
    checked: PropTypes.bool,
};
const defaultProps = {
    title: '',
    onToggleChange: () => {},
    checked: false,
};

ToggleSwitch.propTypes = propTypes;
ToggleSwitch.defaultProps = defaultProps;