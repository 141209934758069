export const LOOKUP = "LOOKUP";
export const LOOKUP_SUCCESS = "LOOKUP_SUCCESS";


export function lookup(data) {
    return {
        payload: data,
        type: LOOKUP,
    };
}

export function lookupSuccess(data) {
    return {
        payload: data,
        type: LOOKUP_SUCCESS,
    };
}
