export const GET_CONTACT_DETAILS = "GET_CONTACT_DETAILS";
export const GET_CONTACT_DETAILS_SUCCESS = "GET_CONTACT_DETAILS_SUCCESS";
export const EDIT_CONTACT_DETAILS = "EDIT_CONTACT_DETAILS";
export const EDIT_CONTACT_DETAILS_SUCCESS = "EDIT_CONTACT_DETAILS_SUCCESS";
export const ADD_EDIT_CONTACT = "ADD_EDIT_CONTACT";
export const ADD_EDIT_CONTACT_SUCCESS = "ADD_EDIT_CONTACT_SUCCESS";
export const REMOVE_CONTACT = "REMOVE_CONTACT";

export function getContactDetails(data) {
    return {
        payload: data,
        type: GET_CONTACT_DETAILS,
    };
}

export function getContactDetailsSuccess(data) {
    return {
        payload: data,
        type: GET_CONTACT_DETAILS_SUCCESS,
    };
}

export function editContactDetails(data, companyData) {
    return {
        payload: data,
        companyData: companyData,
        type: EDIT_CONTACT_DETAILS,
    };
}

export function editContactDetailsSuccess(data) {
    return {
        payload: data,
        type: EDIT_CONTACT_DETAILS_SUCCESS,
    };
}

export function addEditContact(data) {
    return {
        payload: data,
        type: ADD_EDIT_CONTACT,
    };
}

export function addEditContactSuccess(data) {
    return {
        payload: data,
        type: ADD_EDIT_CONTACT_SUCCESS,
    };
}

export function removeContact() {
    return {
        type: REMOVE_CONTACT,
    };
}