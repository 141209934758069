import {BASE_URL, AuthHeaders, PublicApiUri, PublicApiKey, SpiderApiUri} from "./config";
import axios from "axios";

export const sortMyActivities = async (data) => {
  const response = await axios.get(
    `${BASE_URL}/activities/mysort?direction=${data.direction}&limit=${data.limit}&sort=${data.sort}`,
    {
        headers: {
            ...AuthHeaders()
        },
    }
);
return response.data;
};

export const fetchActivityById = async (data) => {
    const id = data && data.id ? data.id : '';
    const type = data && data.type === 'PhoneCall' ? 'phonecalls': 'appointments';
    const response = await axios.get(
        `${BASE_URL}/activities/${type}/${id}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};

export const addEditActivity = async (activity) => {
    const _id = activity && activity.id > 0 ? activity.id : 0;
    let _type = activity.activityType === 'phoneCall' ? 'phonecalls': 'appointments';

    const options = {
      method: _id === 0 ? 'POST' : 'PUT',
      headers: {
        ...AuthHeaders()
      },
    };
    let addEditUrl = `${BASE_URL}/activities/${_type}`;
    let response;
    if (_id > 0) {
        // Save Existing
        addEditUrl = `${addEditUrl}/${_id}`;
        response = await axios.put(addEditUrl, activity, options)
    } else {
        // Create New
        response = await axios.post(addEditUrl, activity, options);
    }
    return response.data;
  };