


export const formatRoute = (routePath, params) => {
    if (params) {
        var tokens = {};

        for (var paramName in params) {
            if (params.hasOwnProperty(paramName)) {
                var paramValue = params[paramName];

                if (paramName === "splat") {
                    // special param name in RR, used for "*" and "**" placeholders
                    paramValue = toArray(paramValue); // when there are multiple globs, RR defines "splat" param as array.
                    var i = 0;
                    routePath = routePath.replace(reSplatParams, function (match) {
                        var val = paramValue[i++];
                        if (val == null) {
                            return "";
                        } else {
                            var tokenName = 'splat' + i;
                            tokens[tokenName] = match === "*" ? encodeURIComponent(val)
                                // don't escape slashes for double star, as "**" considered greedy by RR spec
                                : encodeURIComponent(val.toString().replace(/\//g, "_!slash!_")).replace(reSlashTokens, "/");
                            return '<' + tokenName + '>';
                        }
                    });
                } else {
                    // Rougly resolve all named placeholders.
                    // Cases:
                    // - "/path/:param"
                    // - "/path/(:param)"
                    // - "/path(/:param)"
                    // - "/path(/:param/):another_param"
                    // - "/path/:param(/:another_param)"
                    // - "/path(/:param/:another_param)"
                    var paramRegex = new RegExp('(\/|\\(|\\)|^):' + paramName + '(\/|\\)|\\(|$)');
                    routePath = routePath.replace(paramRegex, function (match, g1, g2) {
                        tokens[paramName] = encodeURIComponent(paramValue);
                        return g1 + '<' + paramName + '>' + g2;
                    });
                    var paramRegexRR4 = new RegExp('(.*):' + paramName + '\\?(.*)');
                    routePath = routePath.replace(paramRegexRR4, function (match, g1, g2) {
                        tokens[paramName] = encodeURIComponent(paramValue);
                        return g1 + '<' + paramName + '>' + g2;
                    });
                }
            }
        }
    }

    return routePath
        // Remove braces around resolved optional params (i.e. "/path/(value)")
        .replace(reResolvedOptionalParams, "$1")
        // Remove all sequences containing at least one unresolved optional param
        .replace(reUnresolvedOptionalParams, "")
        // Remove all sequences containing at least one unresolved optional param in RR4
        .replace(reUnresolvedOptionalParamsRR4, "")
        // After everything related to RR syntax is removed, insert actual values
        .replace(reTokens, function (match, token) {
            return tokens[token];
        })
        // Remove repeating slashes
        .replace(reRepeatingSlashes, "/")
        // Always remove ending slash for consistency
        .replace(/\/+$/, "")
        // If there was a single slash only, keep it
        .replace(/^$/, "/");
}

const toArray = (val) => {
    return Object.prototype.toString.call(val) !== '[object Array]' ? [val] : val;
}
// Cached regexps:

var reRepeatingSlashes = /\/+/g; // "/some//path"
var reSplatParams = /\*{1,2}/g; // "/some/*/complex/**/path"
var reResolvedOptionalParams = /\(([^:*?#]+?)\)/g; // "/path/with/(resolved/params)"
var reUnresolvedOptionalParams = /\([^:?#]*:[^?#]*?\)/g; // "/path/with/(groups/containing/:unresolved/optional/:params)"
var reUnresolvedOptionalParamsRR4 = /(\/[^\/]*\?)/g; // "/path/with/groups/containing/unresolved?/optional/params?"
var reTokens = /<(.*?)>/g;
var reSlashTokens = /_!slash!_/g;