import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './FeedContainer.css';
import {SolIconBubble} from "@solstice/sol-react";
import FeedCard from '../../FeedCard/FeedCardMobile'
import trophy from '../../../assets/trophy.svg'
import contactModified from '../../../assets/contactModified.svg'
import companyModified from '../../../assets/companyModified.svg'
import InfiniteScroll from "../../InfiniteScroll/InfiniteScrollComponent";
import CompanyDetailsHeader from '../../../components/CompanyDetailsHeader/CompanyDetailsHeader';
import {getUser} from "../../../utils/tokenManager";
import archivedPill from '../../../assets/archivedPill.svg'
import moment from 'moment';
import {Oval} from "react-loader-spinner";
import { setGA4Data } from "../../../utils/googleAnalytics";


class FeedContainerMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFeedHeaderTab: 'Show All',
            count: 0
        };
        this.handleNext = this.handleNext.bind(this);
        this.generateFeedCards = this.generateFeedCards.bind(this);
        this.generateFeedCardCompanyDetails = this.generateFeedCardCompanyDetails.bind(this);
    }

    componentDidMount() {
        const { companyId } = this.props;
        const searchParams = new URLSearchParams(window.location.search);
        const tabType = searchParams.get('tabType');
        if(tabType) {
            this.setState({ selectedFeedHeaderTab: tabType});
        }
        setGA4Data('CompDetailsFeed', {
            companyID: companyId
        });

    }

    isOwner = (item) => {
        const user = getUser().split('@')[0].replace('.', ' ');
        return (user === item.feedUser)
    }


    selectedFeedHeaderTabChange = (value) => {
        const {getCompanyFeed, companyId, company} = this.props;
        let allowCall = {
            'Opportunities':   company?.companyOpportunitiesFeed?.length < company?.oppCount,
            'Activities':  company?.companyActivityFeed?.length < company?.activityCount,
            'Show All': company?.companyFeed?.length < company?.totalCount
        }
        this.setState({selectedFeedHeaderTab: value});

        if (company?.companyInformation?.id) {
            if (allowCall[value]) {
                getCompanyFeed({
                    id: companyId,
                    limit: 15,
                    offset: value?.toLowerCase() === 'show all' ? parseInt(company?.companyFeed?.length / 15) + 1 : (value === 'Opportunities' ? parseInt(company?.companyOpportunitiesFeed?.length / 15) + 1 : parseInt(company?.companyActivityFeed?.length / 15) + 1),
                    opportunitiesOnly: value.toLowerCase() === 'opportunities' ? true : undefined,
                    activitiesOnly: value.toLowerCase() === 'activities' ? true : undefined
                });

            }
        }

    }

    generateFeedCardCompanyDetails() {
        const { company } = this.props;
        const  { selectedFeedHeaderTab } = this.state;
        return (
            <>
            <CompanyDetailsHeader company={company?.companyInformation}/>
             {company?.companyFeed?.length >= 0 && <div>
            <div className='header-highlight-div header-div-FeedContainerMobile width-100pc'>
                <div onClick={() => {
                    this.selectedFeedHeaderTabChange('Show All')
                }}
                     className={'header-text ' +
                         (selectedFeedHeaderTab.toLowerCase() === 'show all' ?
                             'highlight-header-text' :
                             'nonHighlight-header-text')}
                >
                    Show All
                </div>
                <div onClick={() => {
                    this.selectedFeedHeaderTabChange('Activities')
                }}
                     className={'header-text ' +
                         (selectedFeedHeaderTab.toLowerCase() === 'activities' ?
                             'highlight-header-text' :
                             'nonHighlight-header-text')}
                >
                    Activities
                </div>
                <div onClick={() => {
                    this.selectedFeedHeaderTabChange('Opportunities')
                }}
                     className={'header-text ' +
                         (selectedFeedHeaderTab.toLowerCase() === 'opportunities' ?
                             'highlight-header-text' :
                             'nonHighlight-header-text')}
                >
                    Opportunities
                </div>
            </div>
        </div>}
            </>
        )
    }

    generateFeedCards(type, item) {
        const { editActivityBtnClicked } = this.props;
        if(type?.toLowerCase().includes('activity')) {
            return (
                (item.feedDate || item?.feedUser) ? <FeedCard
                    header={`${item.activity.isSubstantialActivity ? 'Substantial ' : ''}Activity`}
                    isEditable={this.isOwner(item) && item.activity.activityType}
                    handleEditIconClick={() => {
                        editActivityBtnClicked(item.objectId, item.activity.activityType)
                    }}
                    headerIconName={'vital_signs'}
                    subHeader={(!item.activity.isPrivate || this.isOwner(item)) && item.activity.subject}
                    isPrivate={item.activity.isPrivate}
                    content={[
                        item.feedDate && {
                            'label': 'Activity on',
                            'value': moment(item.feedDate).format('LL'),
                            'noBorder': item.activity.isPrivate && !this.isOwner(item),
                        },
                        (!item.activity.isPrivate || this.isOwner(item)) && {
                            'label': 'Type',
                            'value': item.activity.activityType === 'PhoneCall' ? 'Phone Call' : item.activity.activityType === 'Appointment' ? 'Meeting' : item.activity.activityType,
                            'contentIcon': item.activity.activityType === 'PhoneCall' ? 'phone_forwarded'
                                : item.activity.activityType === 'Appointment' ? 'handshake'
                                    : 'playlist_add_check'
                        },
                        (!item.activity.isPrivate || this.isOwner(item)) && {
                            'label': 'Owner',
                            'value': item?.feedUser,
                            'noBorder': (!item.activity.isPrivate || this.isOwner(item)) && !item.activity.description,

                        },
                    ]}
                    headerIconVariant={item.activity.isSubstantialActivity ? 'accent' : ''}
                    feedDescription={(!item.activity.isPrivate || this.isOwner(item)) && item.activity.description}
                /> : <div/>
            )
        } else {
            return (
                (item.feedUser || item.feedDate) ? <FeedCard
                    headerIcon={<div className='recentOpportunityFeed'>
                        <img
                            className='trophy'
                            src={trophy}
                            alt="trophy"
                        />
                    </div>}
                    header={item.objectType}
                    isEditable={false}
                    subHeader={(!item.opportunity.isConfidential || this.isOwner(item)) && item.opportunity.name}
                    isPrivate={item.opportunity.isConfidential}
                    content={[
                        item.feedDate && {
                            'label': 'Meeting Date',
                            'value': moment(item.feedDate).format('LL'),
                            'noBorder': (item.opportunity.isConfidential && !this.isOwner(item)),
                        },
                        (!item.opportunity.isConfidential || this.isOwner(item)) && {
                            'label': 'Pursuit Lead',
                            'value': item.opportunity.pursuitLead,
                        },
                        (!item.opportunity.isConfidential || this.isOwner(item)) && {
                            'label': 'Status',
                            'value': item.opportunity.isDormant ? 'Dormant' : item.opportunity.status === 'Approved' ? item?.opportunity?.status : item?.opportunity?.stateCode,
                        },
                    ]}
                    feedDescription={(!item.opportunity.isConfidential || this.isOwner(item)) && item.opportunity.description}
                /> : <div/>
            )
        }
    }

    renderEmptySection = () => {
        return (
            <>
            {this.generateFeedCardCompanyDetails()}
            <div className="emptyResults-div">
                <div style={{textAlign: 'center'}}>
                    <SolIconBubble size="s40" icon='inbox'/>
                </div>
                <div className="noResultsFound-div">
                    Nothing new
                </div>
                <div className="couldnotFind-div">
                    We couldn't find any records
                </div>
            </div>
            </>
        )
    }

    handleNext(e) {
        const {company, getCompanyFeed, companyId} = this.props;
        const {selectedFeedHeaderTab, count} = this.state;
        let value = selectedFeedHeaderTab.toLowerCase();
        let allowCall = {
            'opportunities':   company?.companyOpportunitiesFeed?.length < company?.oppCount,
            'activities': company?.companyActivityFeed?.length < company?.activityCount,
            'show all': company?.companyFeed?.length < company?.totalCount
        }

        if (company?.companyInformation?.id) {
            if(allowCall[value])  {
                getCompanyFeed({
                    id: companyId,
                    limit: 15,
                    offset: value?.toLowerCase() === 'show all' ? parseInt(company?.companyFeed?.length / 15) + 1 : (value === 'Opportunities' ? parseInt(company?.companyOpportunitiesFeed?.length / 15) + 1 : parseInt(company?.companyActivityFeed?.length / 15) + 1),
                    opportunitiesOnly: value.toLowerCase() === 'opportunities' ? true : undefined,
                    activitiesOnly: value.toLowerCase() === 'activities' ? true : undefined
                });
            }

        }
    }

    render() {
        const {selectedFeedHeaderTab} = this.state;
        const {company, editActivityBtnClicked} = this.props;
        const opportunitiesList = company?.companyOpportunitiesFeed;
        const activitiesList = company?.companyActivityFeed;
        let hasPills = company.companyInformation?.lastSubstantialActivity?.createdOn || company.companyInformation?.recentOpportunity?.opportunityId;

        return (
            <div>
                {selectedFeedHeaderTab.toLowerCase() === 'show all' &&
                    <div>
                        {(company?.companyFeed?.length > 0) ? <div className='FeedItemCardList-div' style={{ maxHeight: hasPills ? 'calc(100vh - 130px)' : 'calc(100vh - 100px)'}}>
                            <InfiniteScroll
                                scrollThreshold={'200px'}
                                dataLength={selectedFeedHeaderTab.toLowerCase() === 'show all' ? company?.totalCount : (selectedFeedHeaderTab.toLowerCase() === 'activities' ? company?.activityCount : company?.oppCount)}
                                hasMore={company?.companyFeed?.length < company?.totalCount}
                                loader={<h4 className="resultsMessage">Loading...</h4>}
                                load={this.handleNext}
                                endMessage={
                                    <p className="resultsMessage">
                                        <b>No More Results</b>
                                    </p>
                                }
                            >
                                {!company?.companyInformation ? <span className="loader"/> :
                                    this.generateFeedCardCompanyDetails()}
                                {selectedFeedHeaderTab.toLowerCase() === 'show all' && (company?.companyFeed?.length >= 0) && company?.companyFeed?.map((item) => {
                                    switch (item?.objectType?.toLowerCase()) {
                                        case 'opportunity': {
                                            return this.generateFeedCards('opportunity', item);
                                        }
                                        case 'contact': {
                                            return ((item?.feedUser || item.feedDate) ? <FeedCard
                                                    header={`Contact ${item.action}`}
                                                    headerIconName={item.action === 'Added' && 'person_add'}
                                                    headerIcon={item.action.includes('Modified') ? <img
                                                        className='contactModified'
                                                        src={contactModified}
                                                        alt="contactModified"
                                                    /> : null}
                                                    content={[
                                                        {
                                                            'label': 'Contact name',
                                                            'value':
                                                                <div className='archivedPill'>
                                                                    {!item.contact.uiActive &&
                                                                        <img src={archivedPill}/>}
                                                                    {`${item.contact.contactName?.split(',')[1]} ${item.contact.contactName?.split(',')[0]}`}
                                                                </div>,
                                                        },
                                                        item.feedDate && {
                                                            'label': 'Date',
                                                            'value': moment(item.feedDate).format('LL'),
                                                        },
                                                        {
                                                            'label': `${item.action === 'Added' ? 'Added' : 'Modified'} by`,
                                                            'value': item?.feedUser,
                                                            'noBorder': true,
                                                        },
                                                    ]}
                                                /> : <div/>
                                            )
                                        }
                                        case 'company': {
                                            return (
                                                (item.feedDate || item?.feedUser) ? <FeedCard
                                                    header={`Company Record ${item?.action === 'Added' ? 'Created' : 'Updated'}`}
                                                    headerIconName={'add_home_work'}
                                                    headerIcon={item.action.includes('update') ? <img
                                                        className='contactModified'
                                                        src={companyModified}
                                                        alt="companyModified"
                                                    /> : null}
                                                    content={[
                                                        item.action === 'Added' && {
                                                            'label': 'Company Record Created',
                                                            'value':
                                                                <div className='archivedPill'>
                                                                    {moment(item?.company?.createdOn).format('LL')}
                                                                </div>,
                                                        },
                                                        {
                                                            'label': 'Date',
                                                            'value': moment(item?.feedDate).format('LL'),
                                                        },
                                                        {
                                                            'label': `${item?.action === 'Added' ? 'Created' : 'Modified'} by`,
                                                            'value': item?.feedUser,
                                                            'noBorder': true,
                                                        },
                                                    ]}
                                                /> : <div/>
                                            )
                                        }
                                        case 'activity': {
                                          return this.generateFeedCards('activity', item)
                                        }
                                        case 'jllcontact': {
                                            return ((item?.feedUser || item.feedDate) ? <FeedCard
                                                    header={`JLL Contact ${item.action}`}
                                                    headerIconName={item.action === 'Added' && 'person_add'}
                                                    headerIcon={item.action.includes('Modified') ? <img
                                                        className='contactModified'
                                                        src={contactModified}
                                                        alt="contactModified"
                                                    /> : null}
                                                    content={[
                                                        {
                                                            'label': 'Contact name',
                                                            'value':
                                                                <div className='archivedPill'>
                                                                    {!item?.jllContact?.isActive &&
                                                                        <img src={archivedPill}/>}
                                                                    {`${item?.jllContact?.jllContactName?.split(',')[1]} ${item?.jllContact?.jllContactName?.split(',')[0]}`}
                                                                </div>,
                                                        },
                                                        item.feedDate && {
                                                            'label': 'Date',
                                                            'value': moment(item.feedDate).format('LL'),
                                                        },
                                                        {
                                                            'label': `${item.action === 'Added' ? 'Added' : 'Modified'} by`,
                                                            'value': item?.feedUser,
                                                            'noBorder': true,
                                                        },
                                                    ]}
                                                /> : <div/>
                                            )
                                        }
                                        default:
                                            break;
                                    }
                                })}

                            </InfiniteScroll>

                        </div>: <div>
                            {company?.isLoading ? <div className="loaderSection"> <Oval
                                visible={true}
                                height="40"
                                width="40"
                                color="#000000"
                                secondaryColor={"gray"}
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            /></div>: <div>{this.renderEmptySection()}</div>}
                        </div>}
                    </div>}

                {selectedFeedHeaderTab.toLowerCase() === 'activities' &&
                    <div>
                        {(activitiesList?.length > 0) ? <div id="infiniteScroll" className='FeedItemCardList-div' style={{ maxHeight: hasPills ? 'calc(100vh - 130px)' : 'calc(100vh - 100px)'}}>
                            <InfiniteScroll
                                scrollThreshold={'200px'}
                                dataLength={company?.activityCount}
                                hasMore={activitiesList?.length < company?.activityCount}
                                loader={<h4 className="resultsMessage">Loading...</h4>}
                                load={this.handleNext}
                                endMessage={
                                    <p className="resultsMessage">
                                        <b>No More Results</b>
                                    </p>
                                }
                            >
                                {this.generateFeedCardCompanyDetails()}
                            {(company.companyActivityFeed?.length > 0) && company?.companyActivityFeed?.map((item) => {
                                return this.generateFeedCards('activity', item)
                            })
                            }
                            </InfiniteScroll>
                            </div> :
                            <div>
                                {company?.isLoading ? <div className="loaderSection"> <Oval
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#000000"
                                    secondaryColor={"gray"}
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /></div>: <div>{this.renderEmptySection()}</div>}
                            </div>
                        }

                    </div>}

                {selectedFeedHeaderTab.toLowerCase() === 'opportunities' &&
                    <div>
                        {(company?.companyOpportunitiesFeed?.length > 0) ? <div id="infiniteScroll" className='FeedItemCardList-div' style={{ maxHeight: hasPills ? 'calc(100vh - 130px)' : 'calc(100vh - 100px)'}}>
                            <InfiniteScroll
                                scrollThreshold={'200px'}
                                dataLength={company?.oppCount}
                                hasMore={company?.companyOpportunitiesFeed?.length < company?.oppCount}
                                loader={<h4 className="resultsMessage">Loading...</h4>}
                                load={this.handleNext}
                                endMessage={
                                    <p className="resultsMessage">
                                        <b>No More Results</b>
                                    </p>
                                }
                            >
                                {!company?.companyInformation ? <span className="loader"/> :
                                 this.generateFeedCardCompanyDetails()}
                            {(opportunitiesList?.length > 0) && opportunitiesList?.map((item) => {
                                return this.generateFeedCards('opportunity', item)
                            })}

                            </InfiniteScroll>
                            </div> :
                            <div>
                                {company?.isLoading ? <div className="loaderSection"> <Oval
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#000000"
                                    secondaryColor={"gray"}
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /></div>: <div>{this.renderEmptySection()}</div>}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

FeedContainerMobile.propTypes = {
    companyId: PropTypes.string,
    getCompanyFeed: PropTypes.func,
    company: PropTypes.object,
    editActivityBtnClicked: PropTypes.bool,
};

FeedContainerMobile.defaultProps = {
    companyId: '',
    getCompanyFeed: () => {},
    company: {},
    editActivityBtnClicked: false,
};

export default FeedContainerMobile;