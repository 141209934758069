
const getGeolocation = googleAddrComponents => ({
  addressLatitude: googleAddrComponents.geometry.location.lat(),
  addressLongitude: googleAddrComponents.geometry.location.lng(),
});

const mapAddress = googleAddrComponents => {
  const address = {};
  googleAddrComponents.address_components.forEach(addressComponent => {
    address[addressComponent.types[0]] = addressComponent.short_name;
    if (addressComponent.types.indexOf('country') !== -1) {
      address.country_full_name = addressComponent.long_name;
    }
    if (addressComponent.types.indexOf('locality') !== -1) {
      address.locality = addressComponent.long_name;
    }
  });

  return address;
};

export default placeId =>
  new Promise((resolve, reject) => {
    if (!window.google) reject('Google api not initialized');

    const map = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    map.getDetails({ placeId }, (result, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const address = mapAddress(result);
        const addressSreetNumber = address.street_number
          ? `${address.street_number} `
          : '';
        resolve({
          addressLine1: `${addressSreetNumber}${address.route}`,
          addressCity: address.locality || address.administrative_area_level_2,
          addressPostalCode: address.postal_code || address.postal_code_prefix,
          addressState: {
            id: address.administrative_area_level_1,
            value: address.administrative_area_level_1,
          },
          addressCountry: {
            id: address.country.toLowerCase(),
            value: address.country_full_name,
          },
          ...getGeolocation(result),
        });
      } else {
        reject(status);
      }
    });
  });
