import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import AutoSuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Typeahead.css';
import debounce from 'lodash.debounce';


const AutoSearch = props => {
    const [autoSuggestions, setAutoSuggestions] = useState([]);
    const [autoFilter, setAutoFilter] = useState('');
    const { searchResults, onSelectSuggestion, user } = props;


    React.useEffect(() => {
    }, [searchResults]);

    const getSearchResults = useMemo(() => debounce(value => {
            }
        , 600), []);

    const getAutoSuggestions = (value) => {

    };

    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
        </div>
    );

    const onAutoSuggestionsFetchRequested = ({ value }) => {
    };

    const onAutoChange = (event, { newValue }) => {
        setAutoFilter(newValue || '');
    };

    const onAutoSuggestionsClearRequested = () => {
        setAutoSuggestions([]);
        setAutoFilter('');
    };

    const onAutoSuggestionSelected = (event, { suggestion }) => {
        event.stopPropagation();
        // handleSearch(suggestion.userName, suggestion.userId);
    };

    const getAutoSuggestionValue = suggestion => suggestion.userName;

    const renderAutoSuggestionContainer = ({ containerProps, children }) => {
        const { menuItemCallback } = props;
        return (
            <div
                {...containerProps}
                className={styles['auto-suggest-search-container']}
            >
                {children}
            </div>
        );
    };

    const shouldRenderAutoSuggestions = () => {
        return true;
    }

    const renderAutoSuggestion = suggestion => (
        <div
            className={suggestion.id > 0 ? styles.autoSuggest : styles.autoSuggestNoResult}
        >
            {suggestion.id === -2 ? <div>No matching results found. <u>Create Company</u ></div> : suggestion.label}
        </div>
    );

    const inputProps = {
        placeholder: 'Search',
        value: autoFilter,
        onChange: onAutoChange,
        onKeyDown: (e) => {
        }
    };

    return (
        <div className={'autoSearchContainer'}>
            <AutoSuggest
                theme={{
                    input: classnames(
                        'auto-suggest-auto-search-input',
                        'input-searchinput-black-magnifying-glass'
                    ),
                    suggestionsContainer: styles['auto-suggest-search-container'],
                    suggestion: styles['auto-suggest-suggestion'],
                    suggestionHighlighted: styles['suggestion-highlighted'],
                }}
                renderInputComponent={renderInputComponent}
                style={{ border: '1px solid green !important' }}
                suggestions={autoSuggestions}
                onSuggestionsFetchRequested={onAutoSuggestionsFetchRequested}
                onSuggestionsClearRequested={onAutoSuggestionsClearRequested}
                getSuggestionValue={getAutoSuggestionValue}
                renderSuggestion={renderAutoSuggestion}
                onSuggestionSelected={onAutoSuggestionSelected}
                renderSuggestionsContainer={renderAutoSuggestionContainer}
                inputProps={inputProps}
                shouldRenderSuggestions={shouldRenderAutoSuggestions}
                blurOnSelect
                focusInputOnSuggestionClick={false}
                className={styles.selectInput}
            />
        </div>
    );
};

AutoSearch.propTypes = {
    actions: PropTypes.shape({}),
    /** Indicates the product name to be displayed after the JLL Logo */
};

AutoSearch.defaultProps = {};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    actions: {
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoSearch);
