export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";


export function getUsers(data) {
    return {
        payload: data,
        type: GET_USERS,
    };
}

export function getUsersSuccess(data) {
    return {
        payload: data,
        type: GET_USERS_SUCCESS,
    };
}

export function getUserProfile(data) {
    return {
        payload: data,
        type: GET_USER_PROFILE,
    };
}

export function getUserProfileSuccess(data) {
    return {
        payload: data,
        type: GET_USER_PROFILE_SUCCESS,
    };
}
