import React from 'react';
import './BlogEntryContainer.css';
import SearchContainerDesktop from "../SearchContainer/SearchContainerDesktop";

const BlogEntryContainerDesktop = (props) => {
  const { setSearchParams } = props;

  return (
    <div className={'pageLeftPanel'}>
      <SearchContainerDesktop setSearchParams={setSearchParams} />
    </div>
  )
}

export default BlogEntryContainerDesktop;