import initialState from '../initialState.json';
import {GET_USER_PROFILE_SUCCESS, GET_USERS_SUCCESS} from "../actions/Users.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload,
            }
        }
        case GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                userProfile: action.payload,
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}