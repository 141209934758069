import countriesUtils from '../countries';

export default (query, country) =>
  new Promise((resolve, reject) => {
    if (!window.google) {
      reject('Google api not initialized');
    }

    const service = new window.google.maps.places.AutocompleteService();
    if (country) {
      service.getPlacePredictions(
        {
          types: ['address'],
          input: query,
          componentRestrictions: {
            ...(country
              ? { country: countriesUtils.getCountryShortName(country) }
              : {}),
          },
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            resolve(results);
          } else {
            reject(status);
          }
        }
      );
    } else {
      service.getPlacePredictions(
        {
          types: ['address'],
          input: query,
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            resolve(results);
          } else {
            reject(status);
          }
        }
      );
    }
  });
