import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './CompanyDetails.css';
import { setGA4Data } from "./../../utils/googleAnalytics";
import CompanyDetailsHeader from "../CompanyDetailsHeader/CompanyDetailsHeader";

const CompanyDetailsMobile = (props) => {
    const { company, companyId } = props;

    useEffect(() => {
        if (companyId) {
            setGA4Data('CompDetailsCompany', {
                companyID: companyId
            });
        }  
    }, []);

    const companyInfo = (label, text, href) => {
        return (
        <div className='companyDetail'>
            {label && <div className='companyDetailLabel'>{label}</div>}
            {href ? <a className='companyDetailText' href={href}>{text}</a>
            : <div className='companyDetailText' style={{ textAlign: label === '' && 'left'}}>{text}</div>}
        </div>
        )
    }

    return (
        <>
            <CompanyDetailsHeader company={company}/>
            <div className='CompanyDetailsMobile'>
                <div className='aboutCompanyTitle'>About company</div>
                {companyInfo('Phone', company.mainPhone, "tel:" + company.mainPhone)}
                {companyInfo('Website', company.website, company.website)}
                {companyInfo('Industry', company.sicIndustryName)}
                {companyInfo('Sub-Industry', company.sicSubIndustryName)}
                {companyInfo('Known As', company.knownAs)}
                {companyInfo('Parent Company', company?.parentCompany?.displayName)}
                {companyInfo('', company.description)}
            </div>

        </>
    )
}

CompanyDetailsMobile.propTypes = {
    company: PropTypes.shape({
        mainPhone: PropTypes.string,
        website: PropTypes.string,
        sicIndustryName: PropTypes.string,
        sicSubIndustryName: PropTypes.string,
        knownAs: PropTypes.string,
        parentCompany: PropTypes.shape({
            displayName: PropTypes.string
        }),
        description: PropTypes.string
    }),
    companyId: PropTypes.string
};

CompanyDetailsMobile.defaultProps = {
    company: {
        mainPhone: '',
        website: '',
        sicIndustryName: '',
        sicSubIndustryName: '',
        knownAs: '',
        parentCompany: {
            displayName: ''
        },
        description: ''
    },
    companyId: ''
};

export default CompanyDetailsMobile;
