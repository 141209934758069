import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CompanyPageModal.css';
import { SolDialog, SolIcon, SolIconBubble } from "@solstice/sol-react";

class CompanyPageModalMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        const { closeBtnClicked, addActivityBtnClicked, addContactBtnClicked } = this.props;
        return (
            <div>
                <SolDialog floating-position="center"
                    id='CompanyPageModal'
                    onSol-close={() => { closeBtnClicked() }}
                >
                    <div slot="body">
                        <div className='add_circle-div'>
                            <SolIconBubble icon="add_circle" size="32"/>
                            <div className='wantToAdd_Text'>What do you want to add?</div>
                        </div>
                        <div className='buttonClass margin-top-20'
                            style={{ background: '#01151C', color: '#FFFFFF' }}
                            onClick={() => {
                                addActivityBtnClicked();
                            }}
                        >
                            <SolIcon icon='vital_signs' style={{ color: '#32A4CA' }} />Activity
                            <SolIcon icon='arrow_forward' style={{ color: '#32A4CA' }} />
                        </div>
                        <div className='buttonClass margin-top-12'
                            style={{ background: '#01151C', color: '#FFFFFF' }}
                            onClick={() => {
                                addContactBtnClicked();
                            }}
                        >
                            <SolIcon icon='person' style={{ color: '#32A4CA' }} />Contact
                            <SolIcon icon='arrow_forward' style={{ color: '#32A4CA' }} />
                        </div>
                    </div>
                </SolDialog>
            </div >
        );
    }
}

CompanyPageModalMobile.propTypes = {
    closeBtnClicked: PropTypes.func,
    addActivityBtnClicked: PropTypes.func,
    addContactBtnClicked: PropTypes.func,
};

CompanyPageModalMobile.defaultProps = {
    closeBtnClicked: () => {},
    addActivityBtnClicked: () => {},
    addContactBtnClicked: () => {},
};

export default CompanyPageModalMobile;