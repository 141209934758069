import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    GET_COMPANY_CONTACTS,
    GET_COMPANY_DETAILS,
    GET_COMPANY_FEED,
    getCompanyContactsSuccess,
    getCompanyDetailsSuccess,
    getCompanyFeedSuccess
} from "../actions/Company.actions";

export function* getCompanyFeedSaga(action) {
    try {
        const response = yield call(
            api.companyApi.fetchCompanyFeed,
            action.payload
        );
        yield put(getCompanyFeedSuccess({...response, activitiesOnly: action.payload.activitiesOnly, opportunitiesOnly: action.payload.opportunitiesOnly, refreshFeed: action.payload.refreshFeed}));
    } catch (e) {
        console.log(e);
    }
}

export function* getCompanyDetailsSaga(action) {
    try {
        const response = yield call(
            api.companyApi.fetchCompanyDetails,
            action.payload
        );
        yield put(getCompanyDetailsSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* getCompanyContactDetailsSaga(action) {
    try {
        const response = yield call(
            api.companyApi.fetchCompanyContacts,
            action.payload
        );
        yield put(getCompanyContactsSuccess({...response, isActive: action.payload.isActive, type: action.payload.type, reset: action.payload.reset}));
    } catch (e) {
        console.log(e);
    }
}

export function* watchGetCompanyFeed() {
    yield takeEvery(GET_COMPANY_FEED, getCompanyFeedSaga);
}
export function* watchGetCompanyDetails() {
    yield takeEvery(GET_COMPANY_DETAILS, getCompanyDetailsSaga);
}

export function* watchGetCompanyContactDetails() {
    yield takeEvery(GET_COMPANY_CONTACTS, getCompanyContactDetailsSaga);
}
export default function* companySagas() {
    yield all([
        watchGetCompanyFeed(),
        watchGetCompanyDetails(),
        watchGetCompanyContactDetails()
    ]);
}