export const GET_SEARCH_DETAILS = "GET_SEARCH_DETAILS";
export const GET_SEARCH_DETAILS_SUCCESS = "GET_SEARCH_DETAILS_SUCCESS";
export const GET_SEARCH_DETAILS_FAILED = "GET_SEARCH_DETAILS_FAILED";
export const REMOVE_SEARCH_DETAILS = "REMOVE_SEARCH_DETAILS";

export function removeSearchDetails() {
    return {
        type: REMOVE_SEARCH_DETAILS,
    };
}

export function getSearchDetails(data) {
    return {
        payload: data,
        type: GET_SEARCH_DETAILS,
    };
}

export function getSearchDetailsSuccess(data) {
    return {
        payload: data,
        type: GET_SEARCH_DETAILS_SUCCESS,
    };
}

export function getSearchDetailsFailed(data) {
    return {
        payload: data,
        type: GET_SEARCH_DETAILS_FAILED,
    };
}
